import React from 'react'
import Button from '../../../../UI/Button'
import ModalWrapper from '../../../../UI/ModalWrapper'

const CancelSlotModal = ({ closeModal, handleCancelReasonModal }) => {
    return (
        <ModalWrapper>
            <div className="fixed top-0 font-ubuntu h-max text-center bottom-0 left-0 right-0 m-auto w-[35vw] bg-white rounded-[30px] px-8 py-14">
                <div className="text-xl font-bold text-center">
                    Are you sure you would like to <br /> cancel your session?
                </div>
                <div className="flex justify-center mt-8 w-full gap-5">
                    <Button
                        className="bg-lighterOrange w-40 text-lightOrange font-semibold"
                        // onClick={closeModal}
                        onClick={() =>
                            handleCancelReasonModal('cancelSlotModal')
                        }
                    >
                        Yes
                    </Button>
                    <Button
                        className="bg-lighterOrange w-40 text-lightOrange font-semibold"
                        onClick={closeModal}
                    >
                        No
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CancelSlotModal
