import React, { useState } from 'react'
import ModalWrapper from '../../../../../UI/ModalWrapper'
import closeIcon from '../../../../../assets/images/close x.svg'
import CancelIcon from '../../../../../assets/images/restrict-icon.png'

const reasonOptions = [
    { id: 1, reason: 'Coach was late for session' },
    { id: 2, reason: 'Have a personal commitment' },
    { id: 3, reason: 'Not interested in the session anymore' },
    { id: 4, reason: 'Other' },
]
const rightArrow = '>'
const leftArrow = '<'

const CancelReasonModal = ({
    handleCancelReasonModal,
    cancelBookedSlotHandler,
    errMessage,
    setErrMessage,
    setBookingDetails,
    bookingDetails,
}) => {
    const [cancelReason, setCancelReason] = useState('')
    const [inputCancelReason, setInputCancelReason] = useState('')

    const activeStyles = `bg-[#F76411] border-[#F76411] text-[#ffffff]`
    const nonActiveStyles = `bg-[#FDF5EE] border-[#FFDBB0] text-[#868686]`

    return (
        <ModalWrapper>
            <div className="fixed top-0 font-ubuntu h-max bottom-0 left-0 right-0 m-auto w-[35vw] bg-white rounded-[30px]">
                <div className="bg-[#FDF5EE] p-2 text-center rounded-t-[15px] font-ubuntu font-bold text-[16px] relative">
                    {cancelReason === 'Other' && (
                        <span
                            className="text-[#868686] text-[20px] cursor-pointer absolute left-[10px] p-2 top-[-2px]"
                            onClick={() => setCancelReason('')}
                        >
                            {leftArrow}
                        </span>
                    )}
                    <span className="flex justify-center items-center">
                        <span>
                            <img
                                src={CancelIcon}
                                alt=""
                                className="w-[20px] mr-2"
                            />
                        </span>
                        Cancel
                    </span>
                    {!errMessage && (
                        <span className="absolute w-[20px] h-[20px] object-contain top-2.5 right-3 cursor-pointer">
                            <img
                                src={closeIcon}
                                alt="close icon"
                                className="h-[22px] w-[17.27px] object-contain"
                                onClick={() =>
                                    handleCancelReasonModal('cancelReasonModal')
                                }
                            />
                        </span>
                    )}
                </div>

                {!errMessage && cancelReason !== 'Other' && (
                    <div className="px-8 py-7 pb-10">
                        <div className="mt-2">
                            <label className="font-ubuntu text-[#868686] text-[15px] flex justify-start">
                                Please choose the reason to cancel your session
                            </label>
                            <div className="flex flex-col my-2 gap-2">
                                {reasonOptions.map(({ id, reason }) => {
                                    if (id === 4) {
                                        return (
                                            <div
                                                className={`h-[35px] border-solid border-2 rounded-full flex items-center justify-between px-5 cursor-pointer ${
                                                    cancelReason === reason
                                                        ? activeStyles
                                                        : nonActiveStyles
                                                }`}
                                                key={id}
                                                onClick={() =>
                                                    setCancelReason(reason)
                                                }
                                            >
                                                <span className="font-ubuntu text-[14px]">
                                                    {reason}
                                                </span>
                                                <span className="text-[#868686]">
                                                    {rightArrow}
                                                </span>
                                            </div>
                                        )
                                    }

                                    return (
                                        <div
                                            className={`h-[35px] border-solid border-2 rounded-full flex items-center px-5 cursor-pointer ${
                                                cancelReason === reason
                                                    ? activeStyles
                                                    : nonActiveStyles
                                            }`}
                                            key={id}
                                            onClick={() =>
                                                setCancelReason(reason)
                                            }
                                        >
                                            <span className="font-ubuntu text-[14px]">
                                                {reason}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="text-center">
                            <button
                                type="button"
                                class="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                                disabled={cancelReason ? false : true}
                                onClick={() => {
                                    // handleCancelReasonModal('cancelReasonModal')
                                    cancelBookedSlotHandler(cancelReason)
                                    setCancelReason('')
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}

                {!errMessage && cancelReason === 'Other' && (
                    <div className="px-8 py-7 pb-10">
                        <div className="">
                            <label className="font-ubuntu text-[#868686] text-[14px] text-left flex flex-start">
                                Please sepecify the reason to cancel your
                                session
                            </label>
                            <input
                                type="text"
                                class="w-full p-1 text-[#F76411] outline-none  border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6 placeholder:text-[#868686] placeholder:text-[12px] mt-3"
                                placeholder="specify"
                                onChange={(e) =>
                                    setInputCancelReason(e.target.value)
                                }
                                value={inputCancelReason}
                            ></input>
                        </div>
                        <div className="text-center">
                            <button
                                type="button"
                                class="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                                disabled={inputCancelReason ? false : true}
                                onClick={() => {
                                    // handleCancelReasonModal('cancelReasonModal')
                                    cancelBookedSlotHandler(inputCancelReason)
                                    setInputCancelReason('')
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}

                {errMessage && (
                    <div className="px-8 py-8 pb-10">
                        <p>{errMessage}</p>
                        <div className="text-center">
                            <button
                                type="button"
                                class="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                                onClick={() => {
                                    handleCancelReasonModal('cancelReasonModal')
                                    //update state defined in UpComingSession.jsx
                                    setBookingDetails((prevState) => {
                                        return {
                                            ...bookingDetails,
                                            booking_status: 'cancelled',
                                        }
                                    })
                                }}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </ModalWrapper>
    )
}

export default CancelReasonModal
