export const coachData = [
    {
        name: 'Coach01',
        type: 'Physical',
    },
    {
        name: 'Coach02',
        type: 'Mental',
    },
    {
        name: 'Coach03',
        type: 'Nutrition',
    },
    {
        name: 'Coach04',
        type: 'Leadership',
    },
    {
        name: 'Coach05',
        type: 'Nutrition',
    }
]