import React from 'react'
import $ from 'jquery';
import logo from '../../assets/images/lp-logo.svg'
import { Link } from 'react-router-dom'

const menuLinks = [
    {
        id: 1,
        text: "Home",
        link: "home",
        target: ""
    },
    {
        id: 2,
        text: "About Us",
        link: "about",
        target: ""
    },
    {
        id: 3,
        text: "Testimonials",
        link: "testimonials",
        target: ""
    },
    {
        id: 4,
        text: "Contact Us",
        link: "contact",
        target: ""
    },
    {
        id: 5,
        text: "Login",
        link: "https://app.hapsmiths.com/login",
        target: "default"
    },
    {
        id: 6,
        text: "Schedule Demo",
        link: "https://calendly.com/haspmiths/demo-happiness",
        target: "blank"
    }
]

export default function NavbarDummy({ handleClickScroll }) {

    const openNav = () => {
        $(this).toggleClass('open');
        $('body').toggleClass('menu-open');
        $('#nav-icon1').toggleClass('open !mr-0');
    }

    return (
        <>
            <a href="javascript:void(0);" id="scroll-to-top-ico">
                {/* <img src="images/infinity_gray.png" align="Infinity Logo"> */}
            </a>

            {/* <!-- Menu --> */}
            <div class="menu-icon-container z-[999]">
                {/* <!-- <em class="menu-label">MENU</em> --> */}
                <div id="nav-icon1" className='mt-9 lp-sm:mt-5 w-[32px] h-[38px] relative cursor-pointer' onClick={openNav}>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                </div>
            </div>
            <div class="menu-container z-[100] fixed flex items-stretch overflow-hidden h-screen w-full pointer-events-none top-0 left-0 bottom-0 right-0 ">
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu flex justify-center items-center fixed h-screen w-full z-[3]">

                    <div className='flex absolute top-[1rem] left-[25px]'>
                        <img src={logo} alt="Logo" className='inline-block w-[35px] lp-md:w-[25px] lp-sm:w-[25px] mr-[6px] mt-[7px]' />

                        <div className='flex flex-col flex-start lp-md:mt-1 lp-sm:mt-1'>
                            <span className='font-constantia text-[#fff] text-[40px] lp-md:text-[28px] lp-sm:text-[26px]'>Hapsmiths</span>
                            <span className='font-ubuntu text-[15px] lp-md:text-[12px] lp-sm:text-[10px] text-[#FFDBB0] font-thin mt-[-3px]'>Your Department Of Happiness</span>
                        </div>
                    </div>

                    <ul className='pl-0 pb-0'>
                        {menuLinks?.map(menuItem => (
                            <li key={menuItem.id} className='font-thin text-[45px] lp-lg:text-[40px] lp-md:text-[30px] lp-sm:text-[25px] text-white min-h-[45px] p-1.5 list-none text-left lp-lg:fifth-child:hidden lp-md:fifth-child:hidden lp-sm:fifth-child:hidden'>
                                <span className='cursor-pointer font-black text-white py-2 px-0 hover:no-underline'
                                    onClick={
                                        () => {
                                            handleClickScroll(menuItem.link, menuItem.target);
                                            openNav()
                                        }
                                    }>{menuItem.text}</span>
                            </li>
                        ))}

                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'>
                            {/* <a href="https://www.instagram.com/createstudio.in/" target="blank">
                                Follow us on <span></span> */}
                            {/* </a> */}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
