import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BASE_URL } from '../keys'

//~ createApi function returns an object, so we need to give an object as a param too.
export const authApi = createApi({

    /*
    ~ reducerPath --> what is this reducer for
    ~ base query --> whether to use fetch or axios
        ? fetchBaseQuery also takes object as parameter
    ~ endpoints --> this also returns an object, takes a parameter and then tou define the name of your endpoints
        ? builder.query for GET request and for other request use builder.mutation
            ! both query and mutation return an object, so provide an object as param
        ? names of endpoints is your choice, when an endpoint is created, a hook will be generated automatically
            ! let's say getData is the endpoint, then for query --> useGetDataQuery hook should be exported and for mutation --> useGetDataMutation should be exported
    */
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        // for every endpoint, a hook will be created. For example: if login is the endpoint then useLoginMutation() will be created.
        //~ here login is the name of endpoint
        login: builder.mutation({
            //~ as you can see, we used builder.mutation because req is 'POST'
            //~ builder param is an object, which has query and mutation methods who also return objects
            //~ inside this mutation/query, you will provide another method called query which also returns an object

            query: (formData) => ({

                //~ this will take only one param, this formData is what we will be sending to the backend, but if you want to send multiple types of data, use an object as param i.e ({formData, otherData})✅ and (formData, otherData)❌

                //? inside this query method, you provide same properties as in fetch api, but an extra thing is url. so what needs to be added after base url will be added here.
                url: 'common/login-step-one/',
                method: 'POST',
                body: formData
            })
        }),
        verifyOtp: builder.mutation({
            query: (formData) => ({
                url: 'common/verify-otp/',
                method: 'POST',
                body: formData
            })
        }),
        updateProfile: builder.mutation({
            query: (formData) => ({
                url: 'common/update-client-profile/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: formData
            })
        }),
        getCurrentUser: builder.query({
            query: () => ({
                url: 'common/get-profile-detail/',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
            })
        }),
    })
})

export const { useLoginMutation, useVerifyOtpMutation, useUpdateProfileMutation, useGetCurrentUserQuery } = authApi