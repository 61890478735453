import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'
import Container from '../../UI/landingPage/Container'
import logo from '../../assets/images/lp-logo.svg'
import playstore from '../../assets/images/playstore.png'
import appstore from '../../assets/images/appstore.png'
import HapsmithsVerticals from '../../assets/images/hap-design.svg'
import NavbarDummy from "./NavbarDummy"

const menuLinks = [
  {
    id: 1,
    text: "Home ",
    href: "#",
  },
  {
    id: 2,
    text: "Happiness",
    href: "#",
  },
  {
    id: 3,
    text: "About Us",
    href: "#",
  },
  {
    id: 4,
    text: "Testimonials",
    href: "#"
  },
  {
    id: 5,
    text: "Contact Us",
    href: "#",
  }
]

const Header = ({ menu, handleMenu, handleClickScroll }) => {

  useEffect(() => {
    AOS.init({ duration: 60000, once: true, })
  }, [])

  return (
    <>
      <header>
        <div className='flex flex-col' id='home'>
          <div className='h-[50vh] bg-[#0C1717]'>
            <Container>
              <div className='flex justify-between h-[60px] items-center py-[45px]'>
                <div className='' data-aos='fade-up'>
                  <Link to="/home" className='flex mt-[12px] lp-sm:mt-0'>
                    <img src={logo} alt="Logo" className='inline-block w-[35px] lp-md:w-[25px] lp-sm:w-[25px] mr-[6px] mt-[7px]' />

                    <div className='flex flex-col flex-start lp-md:mt-1 lp-sm:mt-1'>
                      <span className='font-constantia text-[#fff] text-[40px] lp-md:text-[28px] lp-sm:text-[26px]'>Hapsmiths</span>
                      <span className='font-ubuntu text-[15px] lp-md:text-[12px] lp-sm:text-[10px] text-[#FFDBB0] font-thin mt-[-3px]'>Your Department Of Happiness</span>
                    </div>
                  </Link>
                </div>

                <NavbarDummy handleClickScroll={handleClickScroll} />
              </div>
            </Container>
          </div>
          <div className='h-[45vh] bg-[#fff]'></div>
        </div>

        <div className='flex absolute z-10 w-[100%] h-[calc(100vh-200px)] top-[110px] left-0 lp-md:h-[calc(100vh-100px)] lp-sm:top-[90px]'>
          <div className='bg-[#FDF5EE] w-[40%] h-[100%] absolute top-0'></div>
          {/* <div className='bg-[transparent] h-[100%] absolute top-0'></div> */}

          <Container className="after:content-[''] after:w-[100%] bg-[#FDF5EE] rounded-br-[20vw] z-[11] px-8 relative w-[100%]">
            <div className='flex justify-between items-center gap-4 w-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] px-8'>
              <div className='flex flex-col justify-center w-2/3 lp-md:w-full lp-sm:w-full' data-aos='fade-right'>
                <h1 className='font-constantia text-[55px] lp-lg:text-[40px] lp-md:text-[30px] lp-sm:text-[25px] text-[#0C1717] leading-none font-semibold mb-5'>If you’re reading this,<br /> your company loves you</h1>
                <p className='font-constantia text-[30px] lp-lg:text-[25px] lp-md:text-[18px] lp-sm:text-[17px] text-[#0C1717] leading-tight'>Get unlimited access to Mental health, Fitness and Habit coaches.</p>

                <div className='flex gap-3 mt-8 lp-md:mt-5'>
                  <a href="https://calendly.com/haspmiths/demo-happiness" target="blank" className='flex bg-[#F76411] text-[#fff] lp-md:text-[15px] font-ubuntu text-center w-[250px] h-[50px] lp-lg:w-[18rem] lp-lg:h-[3.5rem] lp-md:w-[12rem] lp-md:h-[3rem] rounded-[35px] font-bold lp-md:font-medium items-center justify-center'>Schedule Demo</a>
                  <Link to="/login" className='flex font-ubuntu border border-[#F76411] text-[#F76411] text-center w-[250px] h-[50px] lp-lg:w-[18rem] lp-lg:h-[3.5rem] lp-md:hidden lp-sm:hidden rounded-[35px] font-bold  items-center justify-center'>Login</Link>
                </div>

                <div className='hidden lp-md:block lp-sm:block mt-7'>
                  <p className='text-[#0C1717] font-medium text-[15px] mb-1'>Download our app from</p>
                  <div className='flex gap-1'>
                    <a href="https://play.google.com/store/apps/details?id=com.hapsmiths.hapsmiths" target="blank" className='w-[10rem]'>
                      <img src={playstore} alt="playstore" />
                    </a>
                    <a href="#" className='w-[8.8rem]'>
                      <img src={appstore} alt="appstore" />
                    </a>
                  </div>
                </div>
              </div>

              <div className='relative lp-lg:w-3/12 lp-mlg:w-1/3 lp-md:hidden lp-sm:hidden'>
                <img src={HapsmithsVerticals} alt="Hapsmiths Verticals" data-aos='fade-down' />
              </div>
            </div>
          </Container>
        </div>
      </header>
    </>
  )
}

export default Header