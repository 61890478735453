import React, { useState } from 'react'
import plusIcon from '../../../assets/images/profile-plus.svg'
import { BASE_URL } from '../../../keys'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import emptyavatar from '../../../assets/images/emptyavatar.webp'

const Left = () => {
    const { data, isError } = useGetCurrentUserQuery()
    const user = data?.data

    return (
        <>
            <div className="w-[30%]">
                {user?.profile_pic.length > 1 ? (
                    <div className="w-96 h-96 mx-auto">
                        <img
                            className="w-96 h-96 rounded-full border-2 border-[#FFC289] object-cover"
                            src={BASE_URL + user?.profile_pic}
                            alt="profile picture"
                        />
                    </div>
                ) : (
                    <div className="w-96 h-96">
                        <img
                            className="w-96 h-96 rounded-full border-2 border-[#FFC289] object-cover"
                            src={emptyavatar}
                            alt="profile picture"
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Left
