import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import sendButton from '../../../../assets/images/send-button.png'
import { useOpenChatMutation, useSendMessageMutation } from '../../../../services/chatApi';
import { useGetCurrentUserQuery } from '../../../../services/authApi'
import { useRef } from 'react';
import { BASE_URL } from '../../../../keys';
import RestrictIcon from '../../../../assets/images/restrict-icon.png'
import loader from '../../../../assets/images/loader.gif'
import Button from '../../../../UI/Button';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser'

function checkForLinkInText(text) {
    // let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
		// let urlRegex1 = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
		// let urlRegex2 = /(https?:\/\/[^\s]+)/g;
		let urlRegex3 = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    return text.replace(urlRegex3, function(url) {
        return '<a href="' + url + '" target="_blank" className="underline">' + url + '</a>';
    });
}

const Conversation = ({ socket }) => {

	const { data } = useGetCurrentUserQuery();
	const user = data?.data;
	const [currentMessage, setCurrentMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const roomId = useSelector((state) => state.coachReducer.roomId);
	const currentChatUser = useSelector((state) => state.coachReducer);
	const coachId = useSelector((state) => state.coachReducer.coach_id);
	const isRelationPaused = useSelector((state) => state.coachReducer.isRelationPaused);
	const coachName = useSelector((state) => state.coachReducer.coachName);
	const [openChat, { isLoading: chatOpenLoading, isSuccess: chatOpenSuccess }] = useOpenChatMutation();
	const [pageNo, setPageNo] = useState(1);
	const [sendMessage, { isLoading: sendMessageLoading, isError: sendMessageError }] = useSendMessageMutation();
	const [errorMessage, setErrorMessage] = useState("");
	const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false);
	const sendFileRef = useRef(null);
	const [file, setFile] = useState(null);
	const [fileURL, setFileURL] = useState(null);
	const [stopMsg, setStopMsg] = useState(false);
	const msgStartRef = useRef(null);
	const msgEndRef = useRef(null);


	const fetchMessages = async (page, userChanged = false) => {
		const formData = new FormData();
		formData.append('person_id', coachId);
		formData.append('page_no', page);

		try {
			const response = await openChat(formData);
			if (userChanged) {
				setHideShowMoreBtn(false)
				setMessages(response?.data?.data);
				if (response?.data?.data.length < 25) {
					setHideShowMoreBtn(true)
				}
				scrollToBottom();
			} else {
				// console.log('response?.data?.data.length', response?.data?.data.length)
				if (response?.data?.data.length == 0) {
					setHideShowMoreBtn(true)
				} else {
				}
				setMessages([...response?.data?.data, ...messages])
				scrollToTop();
			}
			localStorage.setItem('livBookingId', response?.data?.data.at(-1).booking_id)

			if (response?.data?.data[0].booking_category_id === 2) { // 2 is for Therapy which is having one way chat and one hour sessions.
				setStopMsg(true);
			} else {
				setStopMsg(false);
			}
		} catch (error) {

			// toast.error(`Sorry some error occured please refresh this page!`, {
			// 	position: "top-right",
			// 	autoClose: 5000,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// 	theme: "light",
			// });
		}
		return messages;
	}

	const chatOpenHandler = useCallback(async (page, userChanged = true) => {
		fetchMessages(page, userChanged)
	}, [coachId]);


	useEffect(() => {
		setPageNo(1);
		chatOpenHandler(1, true);
		setErrorMessage("");
	}, [coachId, coachName, fileURL])

	useEffect(() => {
		socket.on("receive_message", (data) => {
			setMessages((prev) => ([...prev, data]))
			scrollToBottom()
		})
	}, [socket])

	const scrollToTop = () => {
		msgStartRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
	}

	const scrollToBottom = () => {
		msgEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
	}

	const sendMessageHandler = async () => {
		// if (currentMessage !== "") {
		if (currentMessage !== "" || file) {
			const messageData = {
				room: roomId,
				from_user_id: user.user_id,
				message: currentMessage,
				booking_id: localStorage.getItem('livBookingId'),
				time: new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes(),
				file_link: fileURL,
			}
			await socket.emit("send_message", messageData);

			const formData = new FormData();
			formData.append('message_from', user.user_id);
			formData.append('message_to', coachId);
			formData.append('booking_id', localStorage.getItem('livBookingId'));
			formData.append('message', currentMessage);
			formData.append('message_file', file);
			setMessages((prev) => ([...prev, messageData]))
			const response = await sendMessage(formData);

			scrollToBottom()
			setCurrentMessage("")
			setFileURL(null);
			setFile(null);

			if (response.error) {
				setErrorMessage(response.error.data.data.booking_id[0] || "Something went wrong! Please try again");

			}
		}
	}


	useEffect(() => {
		// scrollToBottom();
	}, [messages, currentChatUser.user_name, currentMessage])

	const handlePageChange = () => {
		setPageNo(prev => prev + 1);
		fetchMessages(pageNo + 1);
	}
	return (

		<div>

			{/* Conversation */}

			<SimpleBar className='lg:h-[40vh] xl:h-[60vh] 2xl:h-[70vh]' forceVisible="y" autoHide={false}>

				<div className="w-full mt-6 pb-4 px-10">

					{chatOpenLoading && !chatOpenSuccess &&
						<img src={loader} className="w-48 h-48 mx-auto my-auto" alt="" />
					}

					{!chatOpenLoading && chatOpenSuccess &&
						<>
							<div ref={msgStartRef} />
							{console.log('hideShowMoreBtn', hideShowMoreBtn)}
							{!hideShowMoreBtn &&
								<div className="flex w-full justify-center my-2 gap-2">
									{messages?.length > 0 &&
										<Button
											onClick={() => handlePageChange()}
											className="bg-heavy font-bold font-ubuntu text-lightOrange px-4">👆</Button>
									}
								</div>
							}
							{messages?.length === 0 &&
								<div className="w-full h-auto my-auto flex justify-center items-center">
									<p className="text-2xl font-bold font-ubuntu">No messages to show...</p>
								</div>
							}

							{
								messages?.filter((d) => moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(d.created_at)), 'days') != 0
									&& moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(d.created_at)), 'days') != 1).map((item, index) => (

										<div
											id={'mess' + index}
											key={item?.created_at ?? index}
											style={{
												marginLeft: item.from_user_id == user?.user_id || item.message_type === "st" ? "auto" : "inherit",
												marginRight: item.from_user_id != user?.user_id || item.message_type === "st" ? "auto" : "inherit",
											}}
											className={`${item.message_type === "st" ?
												"w-max max-w-[85%] relative text-sm rounded-[40px] py-3 px-5" :
												item.message_type === "div" ? " relative text-sm rounded-[40px] py-3 px-5" :
													item?.file_link?.length > 2 ? "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2  bg-[#fdf5ee]" : " w-max max-w-[70%] relative text-sm rounded-[40px] py-3 px-5"}`}
										>


											{item?.file_link?.length > 2 &&
												<img src={(BASE_URL + item?.file_link)} className="w-full mb-3 rounded-[40px]" alt="" />
											}

											{/* For displaying the date-wise division */}
											{
												item.message_type === "div" &&
												<div className="flex flex-row items-center">
													<div className="text-[#868686] font-ubuntu text-xs !ml-[-20px] w-24">
														{HTMLReactParser(checkForLinkInText(`${item.message}`))}
													</div>
													<div className="!w-full">
														<div className="border-t-[2px] border-[#FFE2C0] ml-[10px]"></div>
													</div>
												</div>
											}


											{/* For displaying the status of message */}
											{
												item.message_type === "st" &&
												<div className="flex bg-[#fdf5ee] rounded-3xl p-2 mb-3 border-[#FFDBB0] border-[1px]">
													<span className="w-[80%] text-[13px] pl-2 leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
													<span className="w-[20%] absolute right-8 bottom-6 pt-2 text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
												</div>
											}


											{
												(item?.message_type === "st" || item?.message_type === "div") ? null :
													<div className="w-full flex bg-[#fdf5ee] rounded-3xl p-2">
														{/* <div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{item.message}</div> */}
														<div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</div>
														
														<div className="flex-none absolute right-8 bottom-3 whitespace-nowrap align-text-bottom text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</div>
														{!item.is_deleted && sendMessageLoading &&
															<span className="z-[9999] animate-pulse w-3 h-3 rounded-full bg-orange">
															</span>
														}
													</div>
											}

										</div>

									))
							}


							{/* Yesterday chat */}
							{
								messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 1).length > 0 ?
									<div className="flex flex-row items-center">
										<div className="text-[#868686] font-ubuntu text-xs">
											Yesterday
										</div>
										<div className="w-full">
											<div className="border-[1px] border-[#FFE2C0] ml-[10px]"></div>
										</div>

									</div> : null
							}
							{
								(messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 1))?.map((item, index) => {
									return (

										<>

											<div
												id={'mess' + index}
												key={item?.created_at ?? index}
												style={{
													marginLeft: item.from_user_id == user?.user_id || item.message_type === "st" ? "auto" : "inherit",
													marginRight: item.from_user_id != user?.user_id || item.message_type === "st" ? "auto" : "inherit",
												}}
												className={`${item.message_type === "st" ?
													"w-max max-w-[85%] flex-wrap relative text-sm rounded-[40px] mb-3  p-2" :
													item?.file_link?.length > 2 ? "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2  bg-[#fdf5ee]" :
														"w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2"}`}
											>

												{item?.file_link?.length > 2 &&
													<img src={(BASE_URL + item?.file_link)} className="w-full mb-3 rounded-[40px]" alt="" />
												}

												{/* For displaying the status of message */}
												{
													item.message_type === "st" &&
													<div className="flex bg-[#fdf5ee] rounded-3xl p-4 mb-3 border-[#FFDBB0] border-[1px] justify-center">
														<span className="flex-1 text-[13px] text-center leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
													</div>
												}

												{
													item.message_type === "st" ? null :
														<div className="w-full flex">
															<span className="absolute bottom-0 flex-none text-gray-500 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
															<span className="flex-1 text-[13px] text-left pl-16 pr-8 leading-4 w-full">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>

															{!item.is_deleted && sendMessageLoading &&
																<span className="z-[9999] animate-pulse w-3 h-3 rounded-full bg-orange">

																</span>
															}
														</div>
												}

											</div>

										</>
									)
								})
							}

							{/* Today chat */}
							{
								messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 0)?.length > 0 ?
									<div className="flex flex-row items-center my-2">
										<div className="text-[#868686] font-ubuntu text-xs">
											Today
										</div>
										<div className="w-full">
											<div className="border-[1px] border-[#FFE2C0] ml-[10px]"></div>
										</div>

									</div> : null
							}
							{
								(messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 0))?.map((item, index) => {
									return (
										<>

											<div
												id={'mess' + index}
												key={item?.created_at ?? index}
												style={{
													marginLeft: item.from_user_id == user?.user_id || item.message_type === "st" ? "auto" : "inherit",
													marginRight: item.from_user_id != user?.user_id || item.message_type === "st" ? "auto" : "inherit",
												}}
												className={`${item.message_type === "st" ?
													"w-max max-w-[85%] flex-wrap relative text-sm rounded-[40px] mb-3  p-2" :
													item?.file_link?.length > 2 ? "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2  bg-[#fdf5ee]" :
														"w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2"}`}
											>


												{item?.file_link?.length > 2 &&
													<img src={(BASE_URL + item?.file_link)} className="w-full mb-3 rounded-[40px]" alt="" />
												}
												{/* For displaying the status of message */}
												{
													item.message_type === "st" &&
													<div className="flex bg-[#fdf5ee] rounded-3xl p-2 mb-3 border-[#FFDBB0] border-[1px]">
														<span className="w-[80%] text-[13px] pl-2 leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
														<span className="w-[20%] absolute right-8 bottom-6 pt-2 text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
													</div>
												}

												{
													(item?.message_type === "st" || item?.message_type === "div") ? null :
														<div className="w-full flex bg-[#fdf5ee] rounded-3xl p-2">
															<div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</div>
															<div className="flex-none absolute right-8 bottom-3 whitespace-nowrap align-text-bottom text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</div>

															{!item.is_deleted && sendMessageLoading &&
																<span className="z-[9999] animate-pulse w-3 h-3 rounded-full bg-orange">

																</span>
															}
														</div>
												}

											</div>
										</>
									)
								})
							}
							<div ref={msgEndRef} />
						</>
					}


					{/* Division for Today */}
					{/* <div className="flex my-6">
						<span className="flex-0 text-xs text-gray-400 w-[10%]">Today</span>
						<div className="flex-1 h-0 mt-2 border-t-[1px] border-[#FFE2C0] w-[90%]"></div>
					</div> */}

					{/* Schedule details */}
					{/* <div className="flex relative bg-[#FDF5EE] text-sm rounded-[40px] mb-4 py-2 px-5 w-[90%] m-auto border border-[#FFDBB0]">
						<span className="flex-1 text-[13px] text-center leading-4 italic text-gray-400">
							Your session was rescheduled.
						</span>
						<span className="absolute right-0 pr-4 flex-none text-gray-500 text-[10px]">09.10</span>
					</div> */}


				</div>
			</SimpleBar >


			{/* Footer */}
			<div className='flex flex-col mx-auto'>
				{

					sendMessageError && <div className="w-full absolute bottom-18 text-center text-orange font-ubuntu">
						{errorMessage}
					</div>
				}
				{
					isRelationPaused === "yes" &&
					<div className="w-[97%]  bg-white absolute text-sm text-center text-orange font-ubuntu">
						Your relation with this coach is paused. Hence you can't send messages.
					</div>
				}
				{
					stopMsg == true &&
					<div className="w-[97%] bg-white absolute text-sm text-center text-orange font-ubuntu">
						You cannot send messages to this category.
					</div>
				}
				{
					fileURL &&
					<img src={fileURL} alt="" className="w-16 h-16 absolute bottom-20 left-14" />
				}


				<div className="flex absolute xl:bottom-2 2xl:bottom-6 w-full ">
					<div className="flex bg-[#FDE9D8] rounded-[40px] w-[85%] h-10 ml-4 p-2">
						<input value={currentMessage}
							disabled={(isRelationPaused === "yes") || (stopMsg == true) ? true : false}
							autoComplete="off"
							onChange={(e) => setCurrentMessage(e.target.value)}
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									sendMessageHandler()
								}
							}}
							autoFocus
							className="outline-none w-[95%] bg-[#FDE9D8] px-4 text-sm" name="message-text" id="message-text" placeholder="Type a message" />
						<input ref={sendFileRef}
							disabled={(isRelationPaused === "yes") || (stopMsg == true) ? true : false}
							accept="image/*"
							onChange={(e) => {
								setFile(e.target.files[0]);
								const files = e.target.files[0];
								// if (files.size > (70 * 1024)) {
								// 	toast.error("File size should be less than 70KB");
								// 	setFile(null)
								// } else {

								var reader = new FileReader();
								//eslint-disable-next-line
								var url = reader.readAsDataURL(files);

								reader.onloadend = function (e) {
									setFileURL(...[reader.result])
									//eslint-disable-next-line
								}.bind(this);
								// }

							}} type="file" name="" id="" className="hidden" />
						<div onClick={() => sendFileRef.current.click()} className="flex justify-center items-center border w-[30px] h-[30px] rounded-full bg-white border-heavyBorder ml-2 mt-[-3px] cursor-pointer">
							<span className="text-xl font-bold text-orange-500 mt-[-5px]">+</span>
						</div>
					</div>
					{(isRelationPaused === "yes") || (stopMsg == true) ?
						<div className="pl-2">
							<img
								src={RestrictIcon} alt="" className="border-[#FFFFFF] w-[40px] h-[40px] border-[2px] rounded-3xl cursor-not-allowed" />
						</div> :
						<div className="pl-2">
							<img src={sendButton} onClick={sendMessageHandler} alt="" className="border-[#FFFFFF] w-[40px] h-[40px] border-[2px] rounded-3xl cursor-pointer" />
						</div>

					}
				</div>
			</div>

			<ToastContainer
				position="top-center"
				autoClose={2000}
			/>
		</div >
	)
}

export default Conversation