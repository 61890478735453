import React from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/FormScreen/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/FormScreen/Left'
import Right from '../components/FormScreen/Right'

const FormScreen = () => {
    return (
        <Wrapper>
            <div className="flex">
                <Sidebar />
                <Main>               
                    <Left />
                    <Right />
                </Main>
            </div>
        </Wrapper>
    )
}

export default FormScreen