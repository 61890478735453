import { createSlice } from "@reduxjs/toolkit";

export const pinnedUnpinnnedSlice = createSlice({
  name: "pinnedUnpinned",
  initialState: {
    noteId: null, isPinned: null, clientId: null
  },
  reducers: {
    setPinnedUnpinnedFn: (state, action) => {
      const { id, is_pinned, selectedClient } = action.payload
      state.noteId = id
      state.isPinned = is_pinned
      state.clientId = selectedClient
    }
  }
});

export const { setPinnedUnpinnedFn } = pinnedUnpinnnedSlice.actions;

export const pinnedReducer = pinnedUnpinnnedSlice.reducer