import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import editIcon from '../../../assets/images/edit-icon.svg'
import logoutIcon from '../../../assets/images/logout-icon.svg'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import DOBModal from './Modals/DOBModal'
import GenderModal from './Modals/GenderModal'
import LogoutModal from './Modals/LogoutModal'
import NameModal from './Modals/NameModal'
import moment from 'moment'

const Right = () => {
    //eslint-disable-next-line
    const { data, isLoading, isError, isSuccess } = useGetCurrentUserQuery()
    const user = data?.data
    // console.log(user)
    const [showProfileModals, setShowProfileModals] = useState(null)
    const [nameField, setNameField] = useState(user?.name)
    const [dobField, setDobField] = useState(user?.dob)
    const [genderField, setGenderField] = useState(user?.gender)
    const navigate = useNavigate()
    const closeProfileModal = () => {
        setShowProfileModals(null)
    }

    let day = moment(user?.date_joined, 'YYYY/MM/DD').date()
    let month = 1 + moment(user?.date_joined, 'YYYY/MM/DD').month()
    let monthName = moment(month, 'MM').format('MMMM')
    let year = moment(user?.date_joined, 'YYYY/MM/DD').year()
    const joinedDate = `${day} ${monthName} ${year}`

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setNameField(data?.data?.name)
            setGenderField(data?.data?.gender)
            setDobField(data?.data?.dob)
            // console.log(nameField, genderField, dobField)
        }
        //eslint-disable-next-line
    }, [])
    const handleLogout = () => {
        localStorage.removeItem('livtoken')
        navigate('/login')
    }
    return (
        <div className="bg-white px-10 py-6 rounded-[15px] flex flex-col justify-start w-[65%]">
            <div className="font-constantia text-black text-center font-bold">
                <h1 className="text-3xl mb-3">Profile</h1>
            </div>
            <div className="flex flex-col space-y-7 border-t-[2px] border-b-[2px] border-[#FFDBB0] py-10">
                <div>
                    <div className="flex items-center justify-between border-b border-[#D9D9D9] pb-1">
                        <div className="font-ubuntu text-xl font-bold">
                            {nameField ? nameField : user?.name}
                        </div>
                        <img
                            src={editIcon}
                            alt="edit icon"
                            className="w-[17.27px] h-[22px] object-contain cursor-pointer"
                            onClick={() => {
                                setShowProfileModals('nameEdit')
                            }}
                        />
                    </div>
                    <div className="space-y-1 my-3">
                        <p className="font-ubuntu font-normal text-[#868686] text-[14px]">
                            {user?.email}
                        </p>
                        <p className="font-ubuntu font-normal text-[#868686] text-[14px]">
                            Joined in {joinedDate}
                        </p>
                    </div>
                </div>

                <div className="border-solid border-b border-[#D9D9D9] pb-1">
                    <div className="text-[#868686] text-[13px] mb-1">
                        Born on
                    </div>
                    <div className="flex justify-between">
                        <span className="font-ubuntu font-normal">
                            {dobField ? dobField : user?.dob}
                        </span>
                        <img
                            src={editIcon}
                            alt="edit icon"
                            className="w-[17.27px] h-[22px] object-contain cursor-pointer"
                            onClick={() => {
                                setShowProfileModals('dobEdit')
                            }}
                        />
                    </div>
                </div>

                <div className="border-solid border-b border-[#D9D9D9] pb-1">
                    <div className="text-[#868686] text-[13px] mb-1">
                        You’re identified as
                    </div>
                    <div className="flex justify-between">
                        <span className="font-ubuntu font-normal">
                            {genderField ? genderField : user?.gender}
                        </span>
                        <img
                            src={editIcon}
                            alt="edit icon"
                            className="w-[17.27px] h-[22px] object-contain cursor-pointer"
                            onClick={() => {
                                setShowProfileModals('genderEdit')
                            }}
                        />
                    </div>
                </div>

                <div className="border-solid border-b border-[#D9D9D9] pb-1">
                    <div className="text-[#868686] text-[13px] mb-1">
                        Working in
                    </div>
                    <div className="flex justify-between">
                        <span className="font-ubuntu font-normal">
                            {user?.company_name}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-5 w-[120px] mx-auto flex justify-center">
                <button
                    type="button"
                    className="bg-transparent flex flex-row justify-center items-center space-x-2"
                    onClick={() => {
                        setShowProfileModals('logout')
                    }}
                >
                    <img
                        src={logoutIcon}
                        alt="logout icon"
                        className="w-[20.31px] h-[23px]"
                    />{' '}
                    <span className="font-ubuntu font-bold text-[#F76411] text-[14px]">
                        Log out
                    </span>
                </button>
            </div>

            {showProfileModals === 'logout' && (
                <LogoutModal
                    onClick={handleLogout}
                    onCloseProfileModal={closeProfileModal}
                />
            )}

            {showProfileModals === 'nameEdit' && (
                <NameModal
                    onCloseProfileModal={closeProfileModal}
                    currentName={user?.name}
                    dobField={user?.dob}
                    genderField={user?.gender}
                    nameField={nameField}
                    setNameField={setNameField}
                />
            )}

            {showProfileModals === 'dobEdit' && (
                <DOBModal
                    onCloseProfileModal={closeProfileModal}
                    nameField={user?.name}
                    genderField={user?.gender}
                    dobField={dobField}
                    year={user?.dob.split('-')[2]}
                    month={user?.dob.split('-')[1]}
                    day={user?.dob.split('-')[0]}
                    setDobField={setDobField}
                />
            )}

            {showProfileModals === 'genderEdit' && (
                <GenderModal
                    onCloseProfileModal={closeProfileModal}
                    nameField={user?.name}
                    dobField={user?.dob}
                    genderField={genderField}
                    currentGender={user?.gender}
                    setGenderField={setGenderField}
                />
            )}
        </div>
    )
}

export default Right
