import React, { useState, useEffect, useMemo } from 'react'
import RichTextEditor from 'react-rte';
import moment from 'moment';
import notesExpand from '../../../assets/images/notes-expand.png'
import {
    usePostEditNoteMutation,
    usePostNewNoteMutation,
    useGetClientChatListQuery
} from '../../../services/coachScreenApi';
import api from '../../../services/api';

import { useNavigate } from 'react-router-dom'

const QuickNotes = ({ clientListing, selectedClient, editNoteId }) => {

    const navigate = useNavigate()

    const [notes, setNotes] = useState('')
    const [arrow, setArrow] = useState(RichTextEditor.createValueFromString(``, 'html')); // template literal => ``
    const [noteId, setNoteId] = useState(null)
    const [loading, setLoading] = useState(true)

    const [postNewNote, { isLoading0 }] = usePostNewNoteMutation();
    const [postEditNote, { isLoading1 }] = usePostEditNoteMutation();

    //Listing of clients who have notes
    const { data: getTodayNote } = useGetClientChatListQuery()

    useEffect(() => {
        // console.log('text length', arrow.toString('html').length)
        if (arrow.toString('html').length > 11) {
            saveNote();
        }
    }, [arrow])

    useEffect(() => {
        setLoading(true)
    }, [noteId])

    
    
    useMemo(() => {
        setLoading(true);
        setArrow(RichTextEditor.createValueFromString(``, 'html'));
        api.get(`/common/list-of-users-in-chat/`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            // setLoading(false);
            // console.log('resssssssssssssss', res.data.data);
            // console.log('selectedClient from useEffect', selectedClient);
            const noteForTheDay = res.data.data.filter(e => e.user_id == selectedClient)
            .map(e => {
                return e;
            });
            // console.log('noteForTheDay1', noteForTheDay);
            // console.log('noteForTheDay2', noteForTheDay[0]?.last_note);
            // console.log('noteForTheDay3', noteForTheDay[0]?.last_note?.data);
            
            // if (typeof noteForTheDay[0].last_note !== 'undefined') {
                if (noteForTheDay[0]?.last_note !== null || typeof noteForTheDay[0]?.last_note?.data !== 'undefined') {
                    // console.log('notes from api', noteForTheDay[0]?.last_note?.data);
                    setArrow(RichTextEditor.createValueFromString(noteForTheDay[0]?.last_note?.data, 'html'))
                    setNoteId(noteForTheDay[0]?.last_note?.id)
                } else {
                // console.log('noteForTheDay[0].last_note is null');
                setArrow(RichTextEditor.createValueFromString(``, 'html'))
                setNoteId(null)
            }
            
        })
    }, [selectedClient])
    
    
    
    
    
    
    const saveNote = async () => {
        
        // setNotes(arrow.toString('html'))
        
        if (noteId == null) {
            // console.log("calling add api", 'addNoteId', noteId, 'client_id', selectedClient, 'adding notes', notes);
            
            const formData = new FormData();
            formData.append('client_id', selectedClient);
            formData.append('data', notes);
            if (notes !== '<p><br></p>' && notes !== '' && notes !== 'undefined') {
                const response = await postNewNote(formData);
                const data = await response.data;
                setNoteId(response?.data?.data)
                // console.log('response data', response.data.data);
            }
            setNotes(arrow.toString('html'))
        } else {
            // setLoading(false)
            // console.log('arrow-------------------', arrow.toString('html'));
            // console.log("calling edit api", 'editNoteId', noteId, 'client_id', selectedClient, 'saving notes', notes);
            // console.log("calling edit api", 'editNoteId', noteId, 'client_id', selectedClient, 'saving notes', arrow.toString('html'));
            
            const formData = new FormData();
            formData.append('note_id', noteId);
            formData.append('client_id', selectedClient);
            formData.append('data', arrow.toString('html'));
            // formData.append('data', notes);
            const response = await postEditNote(formData);
            const data = await response.data;
            // setNotes(arrow.toString('html'))
        }
    }
    
    
    // console.log('loading-------------------', loading);
    
    
    
    

    const [fontSize, setFontSize] = useState(12);
    const fontIncrease = () => { setFontSize((prev) => prev + 1) };
    const fontDecrease = () => { setFontSize((prev) => prev - 1) };
    const fullScreen = () => { setFontSize((prev) => prev - 1) };

    const customControl = [
        () => <button className="w-[30px] h-[30px] inline-block text-[16px] m-0 px-0 relative z-10" onClick={fontIncrease}>A<sup>+</sup></button>,
        () => <button className="w-[30px] h-[30px] inline-block text-[16px] m-0 px-0 relative z-10" onClick={fontDecrease}>A<sup>-</sup></button>,
        () => <button className="w-[30px] h-[30px] inline-block text-[16px] m-0 px-0 relative z-10" onClick={() => getClientNotes(selectedClient)}>
            <img src={notesExpand} alt="fullscreen" className="ml-3 h-4" title='Fullscreen' />
        </button>
    ];

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'border-0 ' },
            { label: 'Italic', style: 'ITALIC', className: 'border-0 ' },
            { label: 'Underline', style: 'UNDERLINE', className: 'border-0 ' }
        ],

        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item', className: 'border-0 ' }
        ]
    };

    const getClientNotes = (client_id) => {
        navigate(`/coach-notes/${client_id}/${noteId}`)
    }

    return (
        <aside className="bg-[#FFFFFF] border-[1.5px] border-[#FFDBB0] rounded-2xl w-[250px] mt-3 p-4 flex flex-col justify-center basis-2/4">
            <div className="w-full font-bold text-[1.3rem] font-constantia">
                Notes
            </div>
            <div className="m-auto border w-full h-[30px] rounded-[40px] bg-[#FDF5EE] border-[#FFDBB0] my-3 text-center text-gray-500 text-xs leading-7">{moment().format('DD/MM/YYYY')}</div>

            {
                // loading || noteId ?
                loading || noteId ?
                    <div className='h-full richEditorChat'>
                    <RichTextEditor
                        value={arrow}
                        // onChange={(e) => { setArrow(e); saveNote(); }}
                        onChange={(e) => { setArrow(e) }}
                        toolbarConfig={toolbarConfig}
                        editorStyle={{ fontSize: fontSize + 'px' }}
                        className={`border-0 bg-transparent overflow-hidden h-full overflow-y-auto relative flex flex-col-reverse font-ubuntu richEditor`}
                        customControls={customControl}
                        autoFocus
                        placeholder='Write your notes here...'
                    /></div> :

                    <div className="flex justify-center items-center">
                        Loading...
                    </div>
            }
        </aside>
    )
}

export default QuickNotes