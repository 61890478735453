import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notes: []
}

export const noteSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        setNote(state, action) {
            state.notes = action.payload;
        },
        setPinnedNote(state, action) {
            console.log('data from setPinnedNote slice', state.notes)
            const newArr = state.notes.map((p) => (
                p.id == action.payload.noteId
                    ? { ...p, ["is_pinned"]: action.payload.isPinned }
                    : p
            ))
            const foundIdx = newArr.findIndex(el => el.id == action.payload.noteId)
            const selectedItem = newArr.find((data) => data.id == action.payload.noteId)
            if (selectedItem.is_pinned == 1) {
                newArr.splice(foundIdx, 1)
                newArr.unshift(selectedItem)
                state.notes = newArr;

            } else {
                newArr.splice(foundIdx, 1)
                newArr.push(selectedItem)
                state.notes = newArr;

            }

        }
    }
})

export const { setNote, setPinnedNote } = noteSlice.actions;

export default noteSlice.reducer;