import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentModal: null,
}

export const mediaModalSlice = createSlice({
    name: 'mediaModal',
    initialState,
    reducers: {
        toggleModal(state, action){
            state.currentModal = action.payload
        }
    }
})

export const mediaModalActions = mediaModalSlice.actions;
export const mediaModalReducer = mediaModalSlice.reducer;