import React from 'react'
import HTMLReactParser from 'html-react-parser'

const FAQModal = ({ question, answer, onClick }) => {
  return (
    <div className="fixed top-0 bg-[#42424274] left-0 w-screen h-screen flex justify-center items-center">
        
        <div className="bg-[#FDF5EE] w-[600px] relative overflow-hidden rounded-2xl">
            <h2 className="font-bold text-sm text-center p-3 pr-6 font-ubuntu">{question}</h2>
            <div className="w-full bg-white text-sm p-10 pt-9">
                {HTMLReactParser(answer)}
            </div>
            <span onClick={onClick} className="absolute top-2 cursor-pointer right-3">&#10005;</span>
        </div>

    </div>
  )
}

export default FAQModal