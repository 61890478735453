import React, { useEffect } from 'react'
import Wrapper from '../../UI/Wrapper'
import Sidebar from '../../components/Common/Sidebar'
import Main from '../../components/FormScreen/Main'
import Left from '../../components/Client/Chat/Left'
import Center from '../../components/Client/Chat/Center'
import Right from '../../components/Client/Chat/Right'
import io from 'socket.io-client'
import { useRef } from 'react'
import api from '../../services/api'
import { CHAT_URL } from '../../keys'
import ReactGA from "react-ga"

const socket = io.connect(CHAT_URL, { transports: ['websocket'] })

const Chat = () => {

	//Google Analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname)
	}, [])

	return (
		<Wrapper>
			<div className="flex">
				<Sidebar chats={true} notLoggedIn={false} />
				<Main>
					<div className='container flex space-x-3 mx-auto'>
						<Left socket={socket} />
						<Center socket={socket} />
						<Right socket={socket} />
					</div>
				</Main>
			</div>
		</Wrapper>
	)
}

export default Chat