import React from 'react'
import Button from '../../../../UI/Button'
import ReactStars from "react-rating-stars-component";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGiveRatingMutation } from '../../../../services/coachApi';
import { ToastContainer, toast } from 'react-toastify'

const Feedback = ({ setFeedback, setErrorBooking }) => {

    const [livupRating, setLivupRating] = useState(1);
    const [coachRating, setCoachRating] = useState(1);
    const [inputValue, setInputValue] = useState("");

    const ratingChanged = (newRating) => {
        setLivupRating(newRating);
    };
    const newRatingChanged = (newRating) => {
        setCoachRating(newRating);
    };

    const bookingId = useSelector(state => state.bookingReducer.bookingId);
    const coachId = useSelector(state => state.bookingReducer.coachId);

    //eslint-disable-next-line
    const [giveRating, { isLoading, isSuccess }] = useGiveRatingMutation();

    const giveRatingHandler = async () => {
        const formData = new FormData();

        formData.append('coach_id', coachId);
        formData.append('rating', coachRating);
        formData.append('booking_id', bookingId);
        formData.append('rating_type', 'therapist');
        formData.append('rating_exp_livup', livupRating);
        formData.append('feedback', inputValue);

        try {
            const response = await giveRating(formData);
            const data = response.data
            console.log(data);
            if(data.code === 'Success'){
                toast.success(`Rating Submitted Successfully!`)
                setTimeout(()=>{
                    setFeedback(false)
                    setErrorBooking(false)
                }, 2000)
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <ToastContainer hideProgressBar />
            <div className="fixed top-0 flex flex-col justify-between h-max bottom-0 left-0 right-0 m-auto w-[50vw] bg-white rounded-[30px] overflow-hidden">
                <header className='bg-heavy h-10 flex justify-center items-center text-center text-black text-md font-bold'>Feedback</header>

                <div className="w-full p-8">
                    <span className="text-gray-500">Rate your Experience with livup</span>
                    <ReactStars
                        count={5}
                        value={livupRating}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#f76411"
                    />
                    <br />
                    <span className="text-gray-500">Rate your Experience with the coach</span>
                    <ReactStars
                        count={5}
                        value={coachRating}
                        onChange={newRatingChanged}
                        size={24}
                        activeColor="#f76411"
                    />
                    <br />
                    <span className="text-gray-500">Additional feedback</span> <small className="text-sm text-gray-400">(Optional)</small>
                    <div>
                        <input 
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        type="text" className="border-b-2 border-x-0 border-t-0 border-b-gray-500 focus:outline-none w-full my-2" />
                    </div>
                    <Button
                        onClick={giveRatingHandler}
                        className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] mx-auto font-bold font-ubuntu">Submit</Button>
                </div>
            </div>
        </>
    )
}

export default Feedback