import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../UI/Button';

const SessionExpired = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('livtoken');
        navigate("/login");
    }
    return (
        <div className="fixed z-[99999] top-0 left-0 flex justify-center items-center w-screen h-screen bg-[#404040b4]">
            <div className="w-72 h-36 bg-white rounded-2xl p-5 text-center">
                Session expired! Please logout and login again.
                <div className="flex w-full justify-center my-3 gap-3">
                    <Button onClick={handleLogout} className="bg-heavyBorder font-ubuntu font-bold text-orange">Logout</Button>
                </div>
            </div>
        </div>
    )
}

export default SessionExpired