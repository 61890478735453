import React, { useState, useEffect } from 'react'
// import RescheduleIcon from '../../../assets/images/Reschedule_Icon.png'
import CancelIcon from '../../../assets/images/restrict-icon.png'
import CancelSlotModal from '../Chat/Modals/CancelSlotModal'
import CancelReasonModal from '../Chat/Modals/CancelReasonModal'
import { useGetAllNotesOfAClientQuery } from '../../../services/coachScreenApi'
import loader from '../../../assets/images/loader.gif'
import moment from 'moment'
import { currentTime, currentDate } from '../../../keys'

const momentDateFormat = (dateVal) => moment(dateVal).format('YYYY-MM-DD')
const momentTimeFormat = (timeVal) =>
    moment(timeVal, 'h:mm:ss A').format('HH:mm')

const UpcomingSessions = ({ selectedClient }) => {
    const [cancelModal, setCancelModal] = useState(false)
    const [cancelReasonModal, setCancelReasonModal] = useState(false)

    const [sessionDetails, setSessionDetails] = useState({})
    const {
        data: bookingDetails,
        isLoading,
        isFetching,
    } = useGetAllNotesOfAClientQuery(selectedClient, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })

    useEffect(() => {
        setSessionDetails(bookingDetails?.data?.last_booking)
    }, [bookingDetails, selectedClient])

    // console.log('#$#$#$#$#$#$#$#', sessionDetails)

    const handleCancelReasonModal = (val) => {
        if (val === 'cancelSlotModal') {
            setCancelModal(false)
            setCancelReasonModal(true)
        }

        if (val === 'cancelReasonModal') {
            setCancelModal(false)
            setCancelReasonModal(false)
        }
    }

    let content
    // if current date & booking date are same
    if (
        momentDateFormat(currentDate) ===
        momentDateFormat(sessionDetails?.last_message?.booking_session_date)
    ) {
        // currentime is <= booking time
        if (
            momentTimeFormat(currentTime) <=
            momentTimeFormat(sessionDetails?.last_message?.booking_session_time)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu leading-8 text-center">
                        Upcoming Session
                    </div>
                    <div className="w-full pb-4 font-bold text-[1.2rem] font-ubuntu leading-8 text-center">
                        {moment(sessionDetails?.session_date).format(
                            'DD/MM/YYYY'
                        )}{' '}
                        at {sessionDetails?.session_time}
                        hrs
                    </div>
                    <div className="flex justify-center flex-col w-[155px] mx-auto">
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={() => setCancelModal(true)}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={CancelIcon}
                                    alt="icon"
                                    className="w-[20px]"
                                />
                            </div>
                            Cancel
                        </button>
                    </div>
                </>
            )
        }

        // currentime is >= booking time
        if (
            momentTimeFormat(currentTime) >=
            momentTimeFormat(sessionDetails?.last_message?.booking_session_time)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu font-bold leading-8 text-center">
                        No Upcoming Session
                    </div>
                </>
            )
        }
    }

    // if currentDate & booked Date aren't same
    if (
        momentDateFormat(currentDate) !==
        momentDateFormat(sessionDetails?.last_message?.booking_session_date)
    ) {
        // currentDate > bookedDate
        if (
            momentDateFormat(currentDate) >
            momentDateFormat(sessionDetails?.last_message?.booking_session_date)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu font-bold leading-8 text-center">
                        No Upcoming Session
                    </div>
                </>
            )
        }

        // currentDate < bookedDate
        if (
            momentDateFormat(currentDate) <
            momentDateFormat(sessionDetails?.last_message?.booking_session_date)
        ) {
            content = content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu leading-8 text-center">
                        Upcoming Session
                    </div>
                    <div className="w-full pb-4 font-bold text-[1.2rem] font-ubuntu leading-8 text-center">
                        {moment(sessionDetails?.session_date).format(
                            'DD/MM/YYYY'
                        )}{' '}
                        at {sessionDetails?.session_time}
                        hrs
                    </div>
                    <div className="flex justify-center flex-col w-[155px] mx-auto">
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={() => setCancelModal(true)}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={CancelIcon}
                                    alt="icon"
                                    className="w-[20px]"
                                />
                            </div>
                            Cancel
                        </button>
                    </div>
                </>
            )
        }
    }

    // if status of booked session is cancelled or completed
    if (
        sessionDetails?.status === 'cancelled' ||
        sessionDetails?.status === 'completed'
    ) {
        content = (
            <>
                <div className="w-full text-[1.3rem] font-ubuntu font-bold leading-8 text-center">
                    No Upcoming Session
                </div>
            </>
        )
    }

    return (
        <aside className="bg-[#FFFFFF] border border-[#FFDBB0] rounded-2xl w-[250px] mt-3 p-4 text-center py-6 flex flex-col justify-center basis-2/4">
            {isLoading || isFetching ? (
                <img src={loader} className="w-10 h-10 mx-auto" alt="loader" />
            ) : (
                content
            )}

            {cancelModal && (
                <CancelSlotModal
                    closeModal={() => setCancelModal(false)}
                    handleCancelReasonModal={handleCancelReasonModal}
                />
            )}

            {cancelReasonModal && (
                <CancelReasonModal
                    handleCancelReasonModal={handleCancelReasonModal}
                    booking_id={sessionDetails?.last_message?.booking_id}
                    sessionDetails={sessionDetails}
                    setSessionDetails={setSessionDetails}
                />
            )}
        </aside>
    )
}

export default UpcomingSessions

{
    /* <button type="button" className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]">
                    <div className="mt-0 mr-3">
                        <img src={RescheduleIcon} alt="" className="w-[20px]" />
                    </div>
                    Reschedule
                </button> */
}
