import React, { useRef, useState, useMemo } from 'react'
import moment from 'moment';
import { useEffect } from 'react';
import Scheduler from './Scheduler';
import { useGetCurrentUserQuery } from "../../../services/authApi"

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import thirtymins from './thirtyMins';
import sixtymins from './sixtyMins';
import api from '../../../services/api';


function Calendar() {

	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

	const [date, setDate] = useState([]);
	const [activeMonth, setActiveMonth] = useState(null);
	const activeMonthSwiper = useRef();
	const d = new Date();
	const currentMonth = d.getMonth();
	const [selectedDate, setSelectedDate] = useState(`${new Date().getFullYear()}-${('0' + new Date().getMonth() + 1).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`);

	const {
		data: currentUser,
		isLoading: userLoading,
		isError: userError,
	} = useGetCurrentUserQuery()

	// console.log('coach_time_slot outside useEffect', currentUser?.data?.coach_time_slot);


	const [timeArr, setTimeArr] = useState(currentUser?.data?.coach_time_slot === '30_min' ? thirtymins : sixtymins);

	const [coachSlotData, setCoachSlotData] = useState([]);



	useEffect(() => {
		if (selectedDate) {
			api.get(`employee/get-timeslots/?date=${selectedDate}`, {
				headers: {
					authorization: `Bearer ${localStorage.getItem("livtoken")}`,
				}
			}).then((res) => {
				// console.log('res.data.data.booked_slots', res.data.data.booked_slots)
				setCoachSlotData(res.data.data.booked_slots)
				setTimeArr(currentUser?.data?.coach_time_slot === '30_min' ? thirtymins : sixtymins)
			})
		}
	}, [selectedDate, currentUser])

	// useEffect(() => {
	// 	setTimeArr(currentUser?.data?.coach_time_slot === '30_min' ? thirtymins : sixtymins)
	// }, [currentUser])


	// For setting the time slots for the logged in coach
	const { data: coachSessionTime, isLoading, isError, isSuccess } = useGetCurrentUserQuery();
	// console.log('coach_time_slot: ', coachSessionTime?.data?.coach_time_slot);
	// console.log('coachSessionTime: ', coachSessionTime);

	useEffect(() => {
		const date = [];
		for (let i = 1; i < 32; i++) { date.push(i); }
		setDate(date);
		setActiveMonth(months[currentMonth]);
	}, [])

	const getDaysInMonth = (key) => {
		setActiveMonth(months[key]);

		setDate([]);
		const date = [];

		const array = [0, 2, 4, 6, 7, 9, 11];
		const item = array.find(element => element === key);

		if (key === 1) {
			// console.log('it`s Feb');
			const currentYear = new Date().getFullYear();
			// const currentYear = 2024;
			// console.log(currentYear);
			const leapYear = ((currentYear % 4 == 0) && (currentYear % 100 != 0)) || (currentYear % 400 == 0);

			if (leapYear) {
				for (let i = 1; i < 30; i++) { date.push(i); }
			} else {
				for (let i = 1; i < 29; i++) { date.push(i); }
			}
		} else {
			if (typeof item !== 'undefined') {
				// console.log('31 days');
				for (let i = 1; i < 32; i++) { date.push(i); }
			} else {
				// console.log('30 days');
				for (let i = 1; i < 31; i++) { date.push(i); }
			}
		}
		// console.log(date);
		setDate(date);
	}
	// console.log('timeArr', timeArr)

	useEffect(() => {
		let tempArr = JSON.parse(JSON.stringify(timeArr));
		// console.log('tempArr', tempArr)
		for (let i = 0; i <= tempArr.length; i++) {
			if (coachSlotData.length > 0) {
				for (let j = 0; j <= coachSlotData.length; j++) {
					if (tempArr[i]?.time === coachSlotData[j]?.session_time) {
						if (tempArr[i]) {
							tempArr[i].event = coachSlotData[j]?.user_name
							tempArr[i].clientId = coachSlotData[j]?.user_id
						}
						break;
					}
				}
			}
		}
		setTimeArr(tempArr)
	}, [coachSlotData])

	// console.log('coachSlotData', coachSlotData)

	return (
		<aside className="bg-white rounded-2xl w-[650px] 2xl:w-[900px] p-4 h-[92%] mt-8 mr-20 overflow-hidden">
			<div className="xs:w-full p-4 rounded-[30px]">
				<p className="font-bold text-xl font-constantia">Working hours <span className="text-sm font-ubuntu font-normal pl-10 text-gray-400">{moment(selectedDate).format('dddd')}</span></p>

				{/* Custom Calendar */}
				<div className="border-2 rounded-2xl border-solid border-orange-200 w-full mt-4">
					<div className="text-sm font-ubuntu justify-between">
						<div className="p-4">

							<div className='w-full'>
								<div className='coach-calendar'>
									<Swiper
										// install Swiper modules
										modules={[Navigation, Pagination, Scrollbar, A11y]}
										spaceBetween={40}
										slidesPerView={4}
										className="workingHours w-full px-10"
										navigation
										initialSlide={new Date().getMonth() + 1}
										// pagination={{ clickable: false }}
										pagination={false}
										scrollbar={false}
										onSwiper={(swiper) => console.log(swiper)}
										onSlideChange={() => console.log('slide change')}
									>

										{months.map((month, key) => {
											return (
												<SwiperSlide key={key} ref={activeMonthSwiper} className={`text-center !w-max ${activeMonth === month ? "text-black-900" : "text-gray-400"} font-ubuntu cursor-pointer`}
													onClick={() => getDaysInMonth(key)}>{month}</SwiperSlide>
											)
										})}

									</Swiper>
								</div>

								<div className="w-full flex mt-4 ml-5">
									<ul className="flex flex-wrap w-[500px] overflow-hidden mt-[-3px]">
										{date.map((date, key) => {
											return (
												<li key={key} className={`px-[0.555rem] pt-[5px] pb-[5px] cursor-pointer ${activeMonth === months[new Date().getMonth()] && date == selectedDate?.split("-")[2] ? "rounded-full bg-[#FFC289]" : ""}`}
													onClick={() => {
														setSelectedDate(`${new Date().getFullYear()}-${moment().month(activeMonth).format("MM")}-${moment().date(date).format("DD")}`)
													}}>{date}</li>
											)
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Custom Scheduler */}

				<SimpleBar className='lg:h-[25vh] xl:h-[58vh] 2xl:h-[70vh]' forceVisible="y" autoHide={false}>
					<div className='mt-6'>
						{
							timeArr?.map((item, index) => {
								return (
									<Scheduler
										key={index}
										event={item.event}
										item={item.time}
										clientId={item.clientId}
										sessionTime={coachSessionTime?.data?.coach_time_slot}
									/>
								)
							})
						}
					</div>
				</SimpleBar>

			</div>
		</aside>
	)
}

export default Calendar