import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../keys'

export const coachScreenApi = createApi({
    reducerPath: 'coach',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getDashboardDetail: builder.query({
            query: () => ({
                url: 'employee/dashboard-detail',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        getCoachTimeSlots: builder.query({
            query: (date) => ({
                url: `employee/get-timeslots/?date=${date}`,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        getClientNotesList: builder.query({
            query: () => ({
                url: `common/list-of-users-in-chat/?for_note=1`,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        getClientChatList: builder.query({
            query: () => ({
                url: `common/list-of-users-in-chat/`,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        getAllNotesOfAClient: builder.query({
            query: (userId) => ({
                url: `employee/get-all-notes/${userId}/`,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        cancelSession: builder.mutation({
            query: (formData) => ({
                url: 'employee/cancel-booked-slot/',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
        pinnedUnpinnedNotes: builder.query({
            // query: (noteId, isPinned, clientId) => ({
            query: ({ noteId, isPinned, clientId }) => ({
                // url: `employee/make-note-pinned/${noteId}/${isPinned}/${clientId}`,
                url: `employee/make-note-pinned/${noteId}/${isPinned}/${clientId}/`,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
            }),
        }),
        postNewNote: builder.mutation({
            query: (formData) => ({
                url: 'employee/insert-data-notes/',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
        postEditNote: builder.mutation({
            query: (formData) => ({
                url: 'employee/update-data-note/',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
        reportClient: builder.mutation({
            query: (formData) => ({
                url: 'employee/report-user/',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
    }),
})

export const {
    useGetDashboardDetailQuery,
    useGetCoachTimeSlotsQuery,
    useGetClientNotesListQuery,
    useGetClientChatListQuery,
    useGetAllNotesOfAClientQuery,
    usePinnedUnpinnedNotesQuery,
    usePostNewNoteMutation,
    usePostEditNoteMutation,
    useReportClientMutation,
    useCancelSessionMutation,
} = coachScreenApi
