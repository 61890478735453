import React, { useState } from 'react'
// import Button from '../../../../UI/Button'
import ModalWrapper from '../../../../UI/ModalWrapper'
import closeIcon from '../../../../assets/images/close x.svg'
import CancelIcon from '../../../../assets/images/restrict-icon.png'
import { useCancelSessionMutation } from '../../../../services/coachScreenApi'
import loader from '../../../../assets/images/loader.gif'
import { ToastContainer, toast } from 'react-toastify'
import { useEffect } from 'react'

const CancelReasonModal = ({
    handleCancelReasonModal,
    booking_id,
    setSessionDetails,
    sessionDetails,
}) => {
    const [cancelReason, setCancelReason] = useState('')
    //400 error. Incase of Coach wanting to cancel a session, but the session is already cancelled by Client.
    const [bookingErrMessage, setBookingErrMessage] = useState('')

    const [cancelSession, { isLoading: slotCancellationLoading }] =
        useCancelSessionMutation()
    const cancelSessionHandler = async (e) => {
        e.preventDefault()
        const formData = new FormData()

        formData.append('booking_id', booking_id)
        formData.append('reason', cancelReason)

        try {
            const response = await cancelSession(formData)
            console.log('CANCELLLLLL RESPONSE', response)
            if (response?.data?.code === 'Success') {
                toast.success('Cancelled Successfully!')
                setTimeout(() => {
                    //close modal
                    handleCancelReasonModal('cancelReasonModal')

                    //update state defined in UpComingSession.jsx
                    setSessionDetails((prevState) => {
                        return { ...prevState, status: 'cancelled' }
                    })
                }, 3000)
                setCancelReason('')
            }

            if (response?.error?.status == 400) {
                setBookingErrMessage(response?.error?.data?.data?.booking_id[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <ModalWrapper>
            <ToastContainer hideProgressBar />
            <div className="fixed top-0 font-ubuntu h-max bottom-0 left-0 right-0 m-auto w-[35vw] bg-white rounded-[30px]">
                <div className="bg-[#FDF5EE] p-2 text-center rounded-t-[15px] font-ubuntu font-bold text-[16px] relative">
                    <span className="flex justify-center items-center">
                        <span>
                            <img
                                src={CancelIcon}
                                alt=""
                                className="w-[20px] mr-2"
                            />
                        </span>
                        Cancel
                    </span>
                    {!bookingErrMessage && (
                        <span className="absolute w-[20px] h-[20px] object-contain top-2.5 right-3 cursor-pointer">
                            <img
                                src={closeIcon}
                                alt="close icon"
                                className="h-[22px] w-[17.27px] object-contain"
                                onClick={() =>
                                    handleCancelReasonModal('cancelReasonModal')
                                }
                            />
                        </span>
                    )}
                </div>
                <div className="px-8 py-7 pb-10">
                    {bookingErrMessage && (
                        <>
                            <p>{bookingErrMessage} by Client!</p>
                            <div className="text-center">
                                <button
                                    type="button"
                                    class="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                                    onClick={() => {
                                        handleCancelReasonModal(
                                            'cancelReasonModal'
                                        )
                                        //update state defined in UpComingSession.jsx
                                        setSessionDetails((prevState) => {
                                            return {
                                                ...prevState,
                                                status: 'cancelled',
                                            }
                                        })
                                    }}
                                >
                                    Go Back
                                </button>
                            </div>
                        </>
                    )}

                    {!bookingErrMessage && (
                        <>
                            <div className="flex flex-col justify-start">
                                <label className="font-ubuntu text-[#868686] text-[14px] text-left">
                                    Please sepecify the reason to cancel your
                                    session
                                </label>
                                <input
                                    type="text"
                                    class="w-full p-1 text-[#F76411] outline-none  border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6 placeholder:text-[#868686] placeholder:text-[12px] mt-3"
                                    placeholder="specify"
                                    onChange={(e) =>
                                        setCancelReason(e.target.value)
                                    }
                                    value={cancelReason}
                                ></input>
                            </div>
                            <div className="text-center">
                                <button
                                    type="button"
                                    class="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                                    disabled={cancelReason ? false : true}
                                    onClick={cancelSessionHandler}
                                >
                                    {slotCancellationLoading ? (
                                        <img
                                            src={loader}
                                            className="w-10 h-10 mx-auto"
                                            alt="loader"
                                        />
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CancelReasonModal
