import React from 'react'

const MediaLoading = () => {
    return (
        <div className="flex animate-pulse flex-wrap justify-center w-full gap-2">
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
            <div className="w-40 h-40 rounded-2xl bg-gray-300"></div>
        </div>
    )
}

export default MediaLoading