export const questions = [
    {
        text: 'Are my sessions Anonymous and Confidential?',
        answer: `Yes. No one outside the professional therapists or coach that you speak to will be privy to your
        sessions. It is important you speak to your therapist or coach about any clarifications on anonymity
        and confidentiality.`
    },
    {
        text: 'What is the difference between Psychiatrists and Psychologists?',
        answer: `While both can offer therapy, psychiatrists tend to have a more biomedical approach to treatment and may also prescribe medication, while psychologists typically focus on talk therapy. Further, Psychiatrists often provide shorter-term treatment, while psychologists often provide longer-term
        treatment.`
    },
    {
        text: `Does Hapsmiths provide access to Psychiatrists?`,
        answer: `As Hapsmiths is a well-being platform and not a medical or a health platform, we do not provide Psychiatric services`
    },
    {
        text: 'Why can I send messages to my Fitness Coach but not to my Mental Health Therapist?',
        answer: `It is unethical to provide counselling over messages as it hinders effective communication. It is easier to misinterpret messages. Hence, to ensure you receive nothing but the most effective therapy, we do not allow our therapists access to messages. However, There is a support number provided to communicate information about bookings, timings, rescheduling etc.`
    },
    {
        text: `Can I continue using Hapsmiths after I have left my current job?`,
        answer: `At the moment, we provide our services only to businesses and hence you will not be able access it as an individual. However, give us a call at your new workspace and we will ensure they are integrated with Hapsmiths so that you may continue your relationship with your therapist and
        coach.`
    },
    {
        text: `How do I change coaches or therapists?`,
        answer: `<div>
            <div>We make it super easy to change coaches/ therapists.</div>
            <ol type="a" className="pl-[20px] my-2">
                <li>1)&nbsp;&nbsp; Ensure you have no other upcoming sessions in that field (Mental health / Fitness)</li>
                <li>2)&nbsp;&nbsp; If you have an upcoming session cancel it.</li>
                <li>3)&nbsp;&nbsp; Book your new therapist or coach</li>
            </ol>
        </div>`
    },
    {
        text: `How do I cancel or reschedule a session?`,
        answer: `Both cancel and reschedule buttons are available on the therapist/ coach’s chats page.`
    },
    {
        text: `How do I open the video link for my session?`,
        answer: `<div>
            <ol type="a" className="pl-[20px] my-2">
                <li>1)&nbsp;&nbsp; From your Dashboard, click on chats.</li>
                <li>2)&nbsp;&nbsp; Select you coach /Therapists chat page</li>
                <li>3)&nbsp;&nbsp; Click the blue video icon on the top of the page when it is time for your session</li>
            </ol>
        </div>`
    },
    {
        text: `How long will the link for my session remain active for if I am late?`,
        answer: `The link will stay active for 10 minutes.`
    },
    {
        text: `What if I need emergency help?`,
        answer: `We are not an emergency care or medical health platform and hence do not provide any sort of emergency services. we have provided a sos button with a link to a suicide hotline that has no affiliation, connection or guarantee by Hapsmiths. It is important you do your own research to find the best service provider for your emergency or medical needs.`
    },
    {
        text: `What if a coach does not show up for a session?`,
        answer: `Please contact customer support immediately.`
    },
    {
        text: `Are your coaches LGBTQ friendly?`,
        answer: `Any coach or Therapist who is certified LGBTQ positive has a little rainbow heart attached to their profile.`
    },
    {
        text: `Can I take sessions from anywhere around the world?`,
        answer: `As of now, we have limited our services only to India but will expand globally soon.`
    },
    {
        text: `Can a family member, friend or romantic partner join me for a session?`,
        answer: `Yes, at the discretion of the therapist. However, they will not be able to take sessions individually.`
    }
]