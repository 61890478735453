import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import zoomIcon from '../../../../assets/images/zoom-icon.png'
import dayCalc from '../../../../utilities/dayCalcAlgorithm';
import { timeDifferenceCalculator } from 'time-difference-calculator';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../../../services/api';
import VideoJoinModalClient from '../../../Coach/Chat/Modals/VideoJoinModalClient';
import ReactTimeAgo from 'react-time-ago'

const TimeRemaining = () => {

    const sessionDate = useSelector(state => state.coachReducer.sessionDate);
    const currentChatUser = useSelector(state => state.coachReducer);

    const daysRemaining = dayCalc(new Date(sessionDate), new Date());
    const progressBarWidth = (daysRemaining / 7) * 100;
    const [videoLink, setvideoLink] = useState(null);
    const [videoModal, setvideoModal] = useState(false)
    const [barStyle, setbarStyle] = useState("")


    const handleVideoCall = () => {
        if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.sessionDate)), 'days') < 0) {
            toast.warn(`Your session is not started yet!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.sessionDate)), 'days') > 0) {
            toast.warn(`Your session is completed!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.sessionDate)), 'days') === 0) {
            if (timeDifferenceCalculator(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime).split(" ")[0] <= 30) {
                api.get(`common/get-booking-link/${currentChatUser?.last_message.booking_id}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                    }
                }).then((res) => {
                    //console.log(res, "RES&&")
                    setvideoLink(res.data.data.zoom_link)
                    //setvideoLink(null)
                }).catch((err) => {
                    toast.warn(`Some error occured`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setvideoLink(null)
                })
                setvideoModal(true)
            } else {
                toast.warn(`Sorry not able to access the link!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

    }


    useEffect(() => {
        // console.log(moment(moment().format("YYYY-MM-DD HH:MM")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'days'), "MOMENT DIFF#####")
        if (moment(moment().format("YYYY-MM-DD HH:MM")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'days') > 0) {
            setbarStyle("100%")
        } else if (moment(moment().format("YYYY-MM-DD HH:MM")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'days') < 0) {
            setbarStyle(`${100 / timeDifferenceCalculator(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime).split(" ")[0]}%`)
        } else if (moment(moment().format("YYYY-MM-DD HH:MM")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'days') === 0) {
            if (moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'hours') >= 0) {
                setbarStyle("100%")
            } else {
                setbarStyle(`${100 / Math.abs(moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime)), 'hours'))}%`)
            }
        }
    }, [currentChatUser])


    return (
        <div className="flex font-ubuntu bg-[#FFDBB0] rounded-[40px] w-max ml-16 h-8 items-center">
            <div className="bg-white overflow-hidden rounded-2xl h-2 w-20 my-auto ml-4 relative">
                <div style={{
                    width: barStyle
                }} className="absolute bg-orange h-full top-0 left-0">

                </div>
            </div>

            <p className="flex-1 font-normal text-[13px] mx-3">
                {
                    currentChatUser?.sessionDate ?
                        <ReactTimeAgo date={currentChatUser?.sessionDate + " " + currentChatUser?.sessionTime} locale="en-US" />
                        : null
                }
            </p>

            <div className="flex-none">
                <img src={zoomIcon} onClick={handleVideoCall} alt="" className="border-[#FFFFFF] w-[35px] h-[35px] border-[2px] rounded-3xl" />
            </div>
            {
                videoModal &&
                <VideoJoinModalClient onCloseModal={() => setvideoModal(false)} videoLink={videoLink} currentChatUser={currentChatUser} />
            }
        </div>
    )
}

export default TimeRemaining