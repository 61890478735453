import React from 'react'
import Button from '../../../../../UI/Button'
import ModalWrapper from '../../../../../UI/ModalWrapper'

const SosModal = ({ onCloseModal }) => {
    return (
        <ModalWrapper>
            <div className="bg-white rounded-3xl text-xl !text-center font-ubuntu px-12 py-12 w-[35vw]">

                <div className="font-semibold text-[25px] text-[#F76411]">
                    SOS
                </div>

                <div class="w-full mt-6 mb-0">
                    <div class="text-gray-500 text-sm text-left mb-6"><strong>Disclaimer:</strong> This is not a Hapsmiths service. Please contact your nearest hospital if you are feeling suicidal or at risk of self-harm.</div>
                    
                    <div>
                        <span className='block font-semibold text-[#F76411] text-[20px]'>Suicide Hotline</span>
                        <span className='block font-semibold text-[18px'>Parivarthan</span>
                        <span className='block text-sm'>(Mon to Fri, 1-10pm)</span>
                        <a href="tel:+917676602602" class="text-lightOrange text-[26px] mt-2 block ">+91-7676602602</a>
                    </div>
                </div>
                <div className="flex justify-evenly gap-12 w-full mt-4 text-center">
                    <Button onClick={onCloseModal} className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7 text-[16px]">Close</Button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default SosModal