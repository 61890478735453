import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'

const stats = [
  {
    id: 1,
    num: "62%",
    text: `Increase in Productivity`
  },
  {
    id: 2,
    num: "60%",
    text: `Increase in Performance`
  }, {
    id: 3,
    num: "2.5x",
    text: `Increase in customer satisfaction`
  }, {
    id: 4,
    num: "2x",
    text: `Increase in creative thinking`
  }
]

const About = () => {

  useEffect(() => {
    AOS.init({ duration: 2000, once: true, })
  }, [])

  return (
    <section className='py-20 lp-mlg:py-24 bg-[#FDE9D8] rounded-t-[80px]'>
      <Container>
        <div className='flex flex-wrap flex-row lp-lg:flex-col justify-between w-full lp-md:block'>
          <h2 data-aos='fade-right' className='text-[55px] lp-lg:text-[45px] lp-md:text-[30px] lp-sm:text-[26px] after:content-[""] after:w-[200px] after:h-[3px] after:bg-[#F76411] after:absolute after:-top-8 after:left-0 text-[#0C1717] leading-none font-constantia font-medium mt-5 relative w-1/3 lp-lg:w-full lp-md:w-full lp-sm:w-full'>The impact of Happiness on businesses</h2>

          <div className='w-2/3 lp-lg:w-full px-6 lp-lg:px-0 lp-md:px-0 lp-sm:px-0 flex flex-wrap lp-lg:justify-start justify-end mt-0 lp-lg:mt-8 lp-md:mt-5 lp-sm:mt-5 lp-md:justify-start lp-sm:justify-center lp-md:w-[195px] lp-sm:w-[195px] lp-md:mx-auto lp-sm:mx-auto'>
            {stats.map((stat) => (
              <div key={stat.id} className='w-[9rem] h-[14rem] px-[2rem] bg-[#FDF5EE] odd:bg-[#fff] border-[.08rem] border-[#FFDBB0] rounded-[150px] flex flex-col items-center justify-center -ml-3 lp-md:-ml-2 lp-sm:-ml-2 first:-ml-0 lp-md:first:-ml-0 lp-sm:first:-ml-0 lp-md:third-child:-ml-0 lp-sm:third-child:-ml-0 lp-md:w-[6rem] lp-md:last:w-[6.3rem] lp-sm:last:w-[6.3rem] lp-md:h-[10rem] lp-md:px-[1rem] lp-sm:w-[6rem] lp-sm:h-[10rem] lp-sm:px-[1rem]' data-aos='fade-right'>
                {/* lp-md:basis-1/4 lp-sm:basis-1/4 */}
                <h3 className='text-[35px] lp-md:text-[22px] lp-sm:text-[22px] text-[#0C1717] font-bold w-[75px] lp-md:w-auto lp-sm:w-auto relative mb-5 after:content-[""] after:w-full after:h-[1px] after:bg-[#F76411] after:absolute after:-bottom-2 after:left-0' data-aos='fade-right'>{stat.num}</h3>
                <p className='text-center font-constantia lp-md:text-[14px] lp-sm:text-[14px]' data-aos='fade-right'>{stat.text}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default About