import React from 'react'

const RightMobile = () => {
    return (
        <div className="relative xs:w-screen lg:hidden xl:hidden 2xl:hidden w-[37%]">
            <div className="absolute z-10 left-[15px] top-[-80px] w-[150px] bg-[#F77611] h-[25vh] rounded-b-[80px]"></div>
            <div className="absolute z-10 left-[190px] top-[-80px] w-[150px] bg-[#38DED6] h-[20vh] rounded-b-[80px]"></div>
            <div className="absolute z-10 left-[15px] top-[16vh] w-[150px] bg-[#FFCC33] h-[27vh] rounded-[80px]"></div>
            <div className="absolute z-10 left-[190px] top-[11vh] w-[150px] bg-[#04C362] h-[27vh] rounded-[80px]"></div>
        </div>
    )
}

export default RightMobile