import React from 'react'
import ClientInfo from './ClientInfo'
import UpcomingSessions from './UpcomingSessions'

const Right = () => {
	return (
		<div className="flex-1 ml-8 h-[92%] mt-8">
			<div className='flex flex-col h-full'>
			<ClientInfo />
			<UpcomingSessions />
			</div>
		</div>
	)
}

export default Right