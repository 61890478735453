import faq from '../../assets/images/faq.png'
import support from '../../assets/images/suport.png'
import privacy from '../../assets/images/privacy.png'
import terms from '../../assets/images/terms.png'

export const menu = [
    {
        text: 'FAQs',
        img: faq
    },
    {
        text: 'Support',
        img: support
    },
    {
        text: 'Privacy Policy',
        img: privacy
    },
    {
        text: 'Terms & Conditions',
        img: terms
    }
]