import React, { useState, useEffect, useRef } from 'react'
import zoomIcon from '../../../assets/images/zoom-icon.png'
import exclamationOrange from '../../../assets/images/exclamation-orange.png'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import RichTextEditor from 'react-rte';
import { usePostEditNoteMutation, usePostNewNoteMutation } from '../../../services/coachScreenApi';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import NotesHeader from './NotesHeader'
import { BASE_URL } from '../../../keys'

const NewNote = ({ clientListing, selectedClient, editNoteId }) => {

    // console.log(clientListing, selectedClient, editNoteId, "clientListing, selectedClient, editNoteId")


    const { clientId, noteId } = useParams()

    const selectedClientDetails = clientListing?.find(
        (client) => client.user_id === selectedClient
    )

    const [addId, setAddId] = useState(null)

    const [loading, setloading] = useState(true)


    //eslint-disable-next-line
    const [arrow, setArrow] = useState(RichTextEditor.createValueFromString(``, 'html'));
    // template literal => ``

    const [postNewNote, { isLoading }] = usePostNewNoteMutation();
    const [postEditNote, { isLoadings }] = usePostEditNoteMutation();

    useEffect(() => {
        if (arrow.toString('html').length > 11) {
            // console.log('saving note')
            saveNote();
        }
    }, [arrow])

    useEffect(() => {
        setloading(true)
    }, [addId])

    // console.log(clientId, noteId, "clientId, noteId ")

    const saveNote = async () => {
        // console.log(addId, 'addid from savenote')
        if (editNoteId !== null) {
            const formData = new FormData();
            formData.append('note_id', editNoteId);
            formData.append('client_id', selectedClientDetails?.user_id);
            formData.append('data', arrow.toString('html'));

            const response = await postEditNote(formData);
            const data = await response.data;
        } else {
            if (addId === null) {
                setloading(false);
                // console.log(addId, 'addId from if cond');
                // console.log(arrow.toString('html'), 'html')
                const formData = new FormData();
                formData.append('client_id', selectedClientDetails?.user_id);
                formData.append('data', arrow.toString('html'));
                if (arrow.toString('html') !== '<p><br></p>' && arrow.toString('html') !== '') {
                    const response = await postNewNote(formData);
                    const data = await response.data;
                    setAddId(response.data.data)
                }
            } else {
                // console.log("calling edit api");
                // console.log(editNoteId, 'editNoteId');
                // console.log(addId, 'addId from else cond');

                const formData = new FormData();
                formData.append('note_id', addId);
                // if (editNoteId === null) {
                //     formData.append('note_id', addId);
                // }
                // else {
                //     formData.append('note_id', editNoteId);
                // }
                formData.append('client_id', selectedClientDetails?.user_id);
                formData.append('data', arrow.toString('html'));

                const response = await postEditNote(formData);
                const data = await response.data;
            }
        }
    }

    //eslint-disable-next-line  
    const [fontSize, setFontSize] = useState(13);

    const fontIncrease = () => { setFontSize((prev) => prev + 1) };
    const fontDecrease = () => { setFontSize((prev) => prev - 1) };
    const fullScreen = () => { setFontSize((prev) => prev - 1) };

    const customControl = [
        () => <button className="w-[30px] h-[30px] inline-block text-[13px] m-0 px-0" onClick={fontIncrease}>A<sup>+</sup></button>,
        () => <button className="w-[30px] h-[30px] inline-block text-[13px] m-0 px-0" onClick={fontDecrease}>A<sup>-</sup></button>
    ];
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'FullScreen'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'border-0 margin-0' },
            { label: 'Italic', style: 'ITALIC', className: 'border-0 margin-0' },
            { label: 'Underline', style: 'UNDERLINE', className: 'border-0 margin-0' }
        ],

        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item', className: 'border-0 ' },
            { label: 'Fullscreen', style: 'fullscreen', className: 'border-0 ' }
        ]
    };

    useEffect(() => {
        if (editNoteId) {
            api.get(`employee/get-note/?note=${editNoteId}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                }
            }).then((res) => {
                setArrow(RichTextEditor.createValueFromString(res.data.data.data, 'html'))
            })
        }
    }, [editNoteId])

    return (
        <aside className="bg-white rounded-2xl w-[600px] p-4 h:[80%] xl:h-[92%] mt-12 mr-10">
            {/* Header */}


            <NotesHeader selectedClientDetails={selectedClientDetails} />






            {/* <div className="flex border-b border-[#FFDBB0] pb-6 mb-6">
                <div className="overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                    {typeof selectedClientDetails !== 'undefined' ?
                        <img
                            // src={`${BASE_URL}${selectedClientDetails?.user_profile_pic}` || `${emptyavatar}`}
                            src={`${selectedClientDetails.user_profile_pic}` !== '' ?
                                `${BASE_URL}${selectedClientDetails.user_profile_pic}` :
                                `${emptyavatar}`}
                            alt="profile image"
                            className=" w-[55px] h-[55px] rounded-full"
                        /> :
                        <img
                            src={`${emptyavatar}`}
                            alt="profile image"
                            className="w-[55px] h-[55px] rounded-full"
                        />
                    }
                </div>
                <p className="w-[150px] pl-4 pt-4 font-semibold text-base">
                    {selectedClientDetails?.user_name}
                </p>

                <div className="flex bg-[#FFDBB0] rounded-[40px] w-[250px] h-6 mt-4 ml-8">
                    <div className="flex-1/2 w-[30px] h-[10px] bg-orange-500 rounded-[40px] mt-[7px] ml-3 z-10"></div>
                    <div className="flex-1/2 w-[40px] h-[10px] bg-white rounded-[40px] mt-[7px] ml-[-6px]"></div>
                    <p className="flex-1 font-semibold text-[13px] pl-5 pt-[2px]">
                        <span className="font-bold text-[13px]">2 days</span>{' '} remaining
                    </p>
                    <div className="flex-none">
                        <img
                            src={zoomIcon}
                            alt=""
                            className="border-[#FFFFFF] w-[25px] h-[25px] border-[2px] rounded-3xl"
                        />
                    </div>
                </div>

                // <div className="pt-4 ml-14">
                //     <div className="bg-[#FFFFFF] w-[30px] rounded-[5px]">
                //         <img
                //             src={exclamationOrange}
                //             alt=""
                //             className="h-6 cursor-pointer"
                //         />
                //     </div>
                // </div>
            </div> */}

            {
                loading || editNoteId ?
                    <div className='richEditor'>
                        <RichTextEditor
                            value={arrow}
                            onChange={(e) => { setArrow(e) }}
                            toolbarConfig={toolbarConfig}
                            editorStyle={{ fontSize: fontSize + 'px' }}
                            // className={`border-[2px] rounded-3xl border-[#FFDBB0] bg-transparent overflow-hidden h-[70vh] 2xl:h-[75vh] overflow-y-auto relative flex flex-col-reverse class-for-toolbar font-ubuntu richEditor`}
                            className={`class-for-toolbar`}
                            customControls={customControl}
                            autoFocus
                            placeholder='Write your notes here...'
                            enableSoftNewLineByDefault={false}
                        /></div> :

                    <div className="flex justify-center items-center">
                        Loading...
                    </div>
            }
        </aside>
    )
}

export default NewNote