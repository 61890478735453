import React from 'react'
import { useSelector } from 'react-redux'
import emptyavatar from '../../../../assets/images/emptyavatar.webp'
import { BASE_URL } from '../../../../keys'

const Header = ({ currentChatUser }) => {
    // const Header = () => {
    // const currentChatUser = useSelector(state => state.chatUserReducer.user)
    // console.log('currentChatUser from header', currentChatUser.length);
    return (
        <>

            <div className="relative overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                {currentChatUser &&
                    Object.keys(currentChatUser).length !== 0 ?
                    <img
                        src={`${currentChatUser?.user_profile_pic}` !== '' ?
                            `${BASE_URL}${currentChatUser?.user_profile_pic}` :
                            `${emptyavatar}`}
                        alt="profile image"
                        className="w-[55px] h-[55px] rounded-full object-cover"
                    /> :
                    <img
                        src={`${emptyavatar}`}
                        alt="profile image"
                        className="w-[55px] h-[55px] rounded-full object-cover"
                    />
                }
            </div>
            <p className="w-[150px] pl-4 pt-4 font-semibold text-base">
                {currentChatUser?.user_name}
            </p>
        </>
    )
}

export default Header