import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentOption: 'FAQs'
}

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        changeOption(state, action){
            state.currentOption = action.payload
        }
    }

})

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer