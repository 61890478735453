import React from 'react'
import Button from '../../../../UI/Button'
import loader from '../../../../assets/images/loader.gif'

const BookingError = ({
    message,
    onClickNo,
    onClickYes,
    cancelLoading,
    setErrorBooking,
    setFeedback,
    cancelBookedSlotHandler,
    loadingToCancel
}) => {
    return (
        <div className="fixed top-0 font-ubuntu flex flex-col justify-between h-max text-center bottom-0 left-0 right-0 m-auto w-[35vw] bg-white rounded-[30px] p-8">
            {message}
            <div className="text-xl font-bold mt-3">
                {message.includes('relationship')
                    ? 'Are you sure you want to pause the relationship now?'
                    : message.includes('category')
                    ? 'Are you sure you want to cancel the booked slot?'
                    : message.includes('slot')
                    ? 'At the same date and slot, you have already booked a session with same/other category. Please choose a different date or slot.'
                    : message.includes('rating') &&
                      'Do you want to give rating now?'}
            </div>
            <div className="flex justify-center my-4 w-full gap-5">
                {message.includes('relationship') && (
                    <Button
                        onClick={onClickYes}
                        disabled={cancelLoading}
                        className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] font-bold font-ubuntu"
                    >
                        {cancelLoading ? (
                            <img
                                src={loader}
                                className="w-8 h-8 mx-auto"
                                alt=""
                            />
                        ) : (
                            'Yes'
                        )}
                    </Button>
                )}
                {message.includes('category') && (
                    <Button
                        onClick={cancelBookedSlotHandler}
                        disabled={cancelLoading}
                        className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] font-bold font-ubuntu"
                    >
                        {cancelLoading || loadingToCancel ? (
                            <img
                                src={loader}
                                className="w-8 h-8 mx-auto"
                                alt=""
                            />
                        ) : (
                            'Yes'
                        )}
                    </Button>
                )}
                {message.includes('rating') && (
                    <Button
                        onClick={() => {
                            setErrorBooking(false)
                            setFeedback(true)
                        }}
                        className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] font-bold font-ubuntu"
                    >
                        Yes
                    </Button>
                )}

                <Button
                    onClick={onClickNo}
                    className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] font-bold font-ubuntu"
                >
                    {message.includes('Something went wrong') ||
                    message.includes('booked')
                        ? 'Go back'
                        : 'No'}
                </Button>
            </div>
        </div>
    )
}

export default BookingError
