import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {}
}

export const chatUserSlice = createSlice({
  name: "chatUser",
  initialState,
  reducers: {
    toggleCurrentUser(state, action) {
      state.user = action.payload
    }
  }
})

export const chatUserReducer = chatUserSlice.reducer;
export const chatUserActions = chatUserSlice.actions;