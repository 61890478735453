import "swiper/css";
import "swiper/css/navigation";
import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useDispatch, useSelector } from 'react-redux';
import { useGetSharedMediaQuery } from '../../../../../services/chatApi';
import { BASE_URL } from '../../../../../keys';
import Button from '../../../../../UI/Button';
import { mediaModalActions } from '../../../../../services/mediaModalSlice';
import { useGetCurrentUserQuery } from "../../../../../services/authApi";
import close from '../../../../../assets/images/close.png'

const CarouselModal = ({ initialSlide, pageNo }) => {
    const dispatch = useDispatch();

    const coachId = useSelector(state => state.coachReducer.coach_id);
    const clientId = useSelector(state => state.chatUserReducer.user.user_id);
    const { data: user } = useGetCurrentUserQuery()

    const [userId, setuserId] = useState(null)

    useEffect(() => {
        if (user?.data?.user_type === 'client') {
            setuserId(coachId)
        } else if (user?.data?.user_type === 'coach') {
            setuserId(clientId)
        }
    }, [coachId, clientId])

    const { data: media, isLoading, isSuccess } = useGetSharedMediaQuery({ userId, pageNo });

    // console.log(media?.data)

    return (
        <div className="bg-[#4040407c] font-ubuntu fixed flex justify-center items-center w-screen h-screen top-0 left-0">

            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                navigation={true} modules={[Navigation]}
                initialSlide={initialSlide}
                className="carousalSwiper rounded-2xl w-[70%] h-[95vh] relative">

                {!isLoading && isSuccess && media?.data?.map((item, index) => (
                    <SwiperSlide key={item?.created_at} className="p-3 flex justify-center">

                        <div className="w-11/12 bg-light p-5 relative rounded-2xl ">
                            <Button
                                onClick={() => dispatch(mediaModalActions.toggleModal("SharedMedia"))}
                                className="absolute z-[99999] top-[-10px] right-[-10px] text-gray-600 bg-white h-[35px] w-[35px]">
                            <img src={close} className="w-4 h-4 cursor-pointer object-contain" alt="close icon" />
                            </Button>

                            <div className="w-full h-full rounded-2xl overflow-auto">
                                <img src={BASE_URL + item.file_link} alt="" className="rounded-2xl inline-block align-center" />
                            </div>
                        </div>

                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    )
}

export default CarouselModal