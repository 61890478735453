import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coachName: '',
    roomId: null,
    coach_category: null,
    coach_id: null,
    sessionDate: null,
    sessionTime: null,
    isRelationPaused: null,
    coach_category_id: null,
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        toggleClient(state, action) {
            state.coachName = action.payload.name;
            state.roomId = action.payload.roomId;
            state.coach_category = action.payload.coach_category;
            state.coach_id = action.payload.coach_id;
            state.sessionDate = action.payload.sessionDate;
            state.sessionTime = action.payload.sessionTime;
            state.isRelationPaused = action.payload.isRelationPaused;
            state.coach_category_id = action.payload.coach_category_id;
        }
    }
})

export const { toggleClient } = clientSlice.actions;

export default clientSlice.reducer;