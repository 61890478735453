import React from 'react'
import Backdrop from '../../../UI/Backdrop'

const LogoutModal = (props) => {
    return (
        <>
            <Backdrop />
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999] min-w-[450px] min-h-[200px] bg-white rounded-[15px] px-5 py-10 flex flex-col justify-center">
                <h2 className="font-ubuntu text-center font-bold">
                    Are you sure you want to log out?
                </h2>
                <div className="flex space-x-5 justify-center mt-8">
                    <button
                        onClick={props.onClick}
                        className="bg-[#FFDBB0] text-[#F77611] font-ubuntu font-bold py-2 rounded-full min-w-[120px]"
                    >
                        Yes
                    </button>
                    <button
                        className="bg-[#FFDBB0] text-[#F77611] font-ubuntu font-bold py-2 rounded-full min-w-[120px]"
                        onClick={() => {
                            props.onCloseProfileModal()
                        }}
                    >
                        No
                    </button>
                </div>
            </div>
        </>
    )
}

export default LogoutModal
