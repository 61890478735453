import React, { useState } from 'react'
import editIcon from '../../../assets/images/edit-icon.svg'
import logoutIcon from '../../../assets/images/logout-icon.svg'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import { useNavigate } from 'react-router-dom'
import LogoutModal from './LogoutModal'
import moment from 'moment'

const Right = () => {
    const [showProfileModals, setShowProfileModals] = useState(null)

    //eslint-disable-next-line
    const { data, isLoading, isError, isSuccess } = useGetCurrentUserQuery()
    const user = data?.data
    console.log(user)

    let day = moment(user?.date_joined, 'YYYY/MM/DD').date()
    let month = 1 + moment(user?.date_joined, 'YYYY/MM/DD').month()
    let monthName = moment(month, 'MM').format('MMMM')
    let year = moment(user?.date_joined, 'YYYY/MM/DD').year()
    const joinedDate = `${day} ${monthName} ${year}`

    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem('livtoken')
        navigate('/login')
    }

    const closeProfileModal = () => {
        setShowProfileModals(null)
    }

    return (
        <div className="bg-white px-10 py-6 rounded-[15px] flex flex-col justify-start w-[65%]">
            <div className="font-constantia text-black text-center font-bold">
                <h1 className="text-3xl mb-3">Profile</h1>
            </div>
            <div className="flex flex-col space-y-10 border-t-[2px] border-b-[2px] border-[#FFDBB0] py-10">
                <div>
                    <div className="flex items-center justify-between">
                        <div className="font-ubuntu text-xl font-bold pb-1 pr-5 border-b border-[#FFDBB0]">
                            {user?.name}
                        </div>
                    </div>
                    <div className="space-y-1 my-3">
                        <p className="font-ubuntu font-normal text-[#868686] text-[15px]">
                            Joined in {joinedDate}
                        </p>
                    </div>
                </div>

                <div className="flex gap-10 border-solid w-full">
                    <div className="w-[50%]">
                        <div className="text-[#000] text-[14px] mb-2 border-b border-[#FFDBB0] pb-2 font-semibold">
                            Specifications
                        </div>
                        <div className="flex flex-wrap gap-x-3 gap-y-2">
                            {user?.specializations?.map((item, i) => (
                                <span
                                    key={i}
                                    className="font-ubuntu font-normal text-[13px]"
                                >
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="w-[50%]">
                        <div className="text-[#000] text-[14px] mb-2 border-b border-[#FFDBB0] pb-2 font-semibold">
                            Languages
                        </div>
                        <div className="flex flex-wrap gap-x-3 gap-y-2">
                            {user?.languages?.map((item, i) => (
                                <span
                                    key={i}
                                    className="font-ubuntu font-normal text-[13px]"
                                >
                                    {item?.name}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex gap-10 border-solid w-full">
                    <div className="w-[50%]">
                        <div className="text-[#000] text-[14px] mb-2 border-b border-[#FFDBB0] pb-2 font-semibold">
                            Email ID
                        </div>
                        <div>
                            <span className="font-ubuntu font-normal text-[13px]">
                                {user?.email}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex gap-10 border-solid w-full">
                    <div className="w-[50%]">
                        <div className="text-[#000] text-[14px] mb-2 border-b border-[#FFDBB0] pb-2 font-semibold">
                            Number of clients
                        </div>
                        <div>
                            <span className="font-ubuntu font-normal text-[13px]">
                                {user?.no_of_clients}
                            </span>
                        </div>
                    </div>

                    <div className="w-[50%]">
                        <div className="text-[#000] text-[14px] mb-2 border-b border-[#FFDBB0] pb-2 font-semibold">
                            Number of sessions
                        </div>
                        <div>
                            <span className="font-ubuntu font-normal text-[13px]">
                                {user?.no_of_sessions}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 w-[120px] mx-auto flex justify-center">
                <button
                    type="button"
                    className="bg-transparent flex flex-row justify-center items-center space-x-2"
                    onClick={() => {
                        setShowProfileModals('logout')
                    }}
                >
                    <img
                        src={logoutIcon}
                        alt="logout icon"
                        className="w-[20.31px] h-[23px]"
                    />{' '}
                    <span className="font-ubuntu font-bold text-[#F76411] text-[14px]">
                        Log out
                    </span>
                </button>
            </div>

            {showProfileModals === 'logout' && (
                <LogoutModal
                    onClick={handleLogout}
                    onCloseProfileModal={closeProfileModal}
                />
            )}
        </div>
    )
}

export default Right
