import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    slots: [],
    booking: [],
    leaves: [],
}

export const slotSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {
        getSlots(state, action) {
            state.slots = action.payload
        },
        getBookedSlots(state, action) {
            state.booking = action.payload
        },
        getLeaveSlots(state, action) {
            state.leaves = action.payload
        },
    },
})

export const slotActions = slotSlice.actions
export const slotReducers = slotSlice.reducer
