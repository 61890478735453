import React, { useState, useCallback, useEffect } from 'react'
import zoomIcon from '../../../assets/images/zoom-icon.png'
import CancelIcon from '../../../assets/images/restrict-icon.png'
import RescheduleIcon from '../../../assets/images/Reschedule_Icon.png'
import CancelSlotModal from './Right/Modals/CancelSlotModal'
import CancelReasonModal from './Right/Modals/CancelReasonModal'
import { useOpenChatMutation } from '../../../services/chatApi'
import { useCancelBookedSlotMutation } from '../../../services/coachApi'
import { data } from 'autoprefixer'
import loader from '../../../assets/images/loader.gif'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { currentTime, currentDate } from '../../../keys'
import { useNavigate } from 'react-router-dom'
import { therapistActions } from '../../../services/therapistSlice'

const momentDateFormat = (dateVal) => moment(dateVal).format('YYYY-MM-DD')
const momentTimeFormat = (timeVal) =>
    moment(timeVal, 'h:mm:ss A').format('HH:mm')

const UpcomingSessions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [cancelModal, setCancelModal] = useState(false)
    const [cancelReasonModal, setCancelReasonModal] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    //to cancel booked slot
    const [cancelBookedSlot, { isLoading: loadingToCancel }] =
        useCancelBookedSlotMutation()

    const [bookingDetails, setBookingDetails] = useState({})
    // To get all the booking details
    const [
        openChat,
        { isLoading: chatOpenLoading, isSuccess: chatOpenSuccess },
    ] = useOpenChatMutation()
    const coachId = useSelector((state) => state.coachReducer.coach_id)
    const chatOpenHandler = useCallback(async () => {
        const formData = new FormData()
        formData.append('person_id', coachId)
        formData.append('page_no', 1)
        try {
            const response = await openChat(formData)
            // console.log(
            //     // '$$$$$$$$$$$$$OpenChat',
            //     response?.data?.data[response?.data?.data.length - 1]
            // )
            setBookingDetails(
                response?.data?.data[response?.data?.data.length - 1]
            )
        } catch (error) {
            console.log(error)
        }
    }, [coachId, openChat])
    useEffect(() => {
        chatOpenHandler()
    }, [coachId, openChat])

    //to cancel booked slot
    const cancelBookedSlotHandler = async (cancel_reason) => {
        const formData = new FormData()
        formData.append('booking_id', bookingDetails?.booking_id)
        formData.append('coach_id', bookingDetails?.booking_coach_id)
        formData.append('category_id', bookingDetails?.booking_category_id)
        formData.append('reason', cancel_reason)

        try {
            const response = await cancelBookedSlot(formData)
            if (response?.data?.code === 'Success') {
                toast.success('Cancelled Successfully!')

                setBookingDetails((prevState) => {
                    return { ...bookingDetails, booking_status: 'cancelled' }
                })
                setErrMessage('')

                setTimeout(() => {
                    handleCancelReasonModal('cancelReasonModal')
                }, 3000)
            } else if (response?.error?.status == 400) {
                if (
                    response?.error?.data?.data?.error[0] ===
                    'Either booking does not exists or does not belongs to you'
                ) {
                    setErrMessage('Booking already cancelled by Coach!')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCancelReasonModal = (val) => {
        if (val === 'cancelSlotModal') {
            setCancelModal(false)
            setCancelReasonModal(true)
        }

        if (val === 'cancelReasonModal') {
            setCancelModal(false)
            setCancelReasonModal(false)
        }
    }

    //Redirect to FindTherapist page -- onCLick of BookNow/Reschedule
    const redirectBookNowOrRescheduleHandler = () => {
        //to determine whether the redirection to FindTherapist page is going from client chat
        dispatch(therapistActions.setBtnClick('yes'))

        navigate(
            '/findtherapist/' +
                bookingDetails?.booking_category_name +
                '?' +
                bookingDetails?.booking_category_id
        )
        dispatch(therapistActions.setBG(bookingDetails?.booking_category_color))
        dispatch(
            therapistActions.setCategoryIcon(
                bookingDetails?.booking_category_icon
            )
        )
        dispatch(
            therapistActions.setCategoryName(
                bookingDetails?.booking_category_name
            )
        )
        localStorage.setItem('Booking_id', bookingDetails?.booking_coach_id)
        console.log(bookingDetails, 'bookingDetails?.booking_coach_id')
        // debugger;
        //dispatch(therapistActions.setCoachIdBN(bookingDetails?.booking_coach_id))
        dispatch(therapistActions.setCoachIdBN(bookingDetails?.to_user_id))
    }

    let content
    // if current date & booking date are same
    if (
        momentDateFormat(currentDate) ===
        momentDateFormat(bookingDetails?.booking_session_date)
    ) {
        // currentime is <= booking time
        if (
            momentTimeFormat(currentTime) <=
            momentTimeFormat(bookingDetails?.booking_session_time)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu leading-8 text-center">
                        Upcoming Session
                    </div>
                    <div className="w-full pb-4 font-bold text-[1.2rem] font-ubuntu leading-8 text-center">
                        {moment(bookingDetails?.booking_session_date).format(
                            'DD/MM/YYYY'
                        )}{' '}
                        at {bookingDetails?.booking_session_time}hrs
                    </div>

                    {/* <div className="m-auto flex justify-center text-center pb-4">
                        <img
                            src={zoomIcon}
                            alt=""
                            className="border-[#FFFFFF] w-[50px] h-[50px] border-[2px] rounded-3xl"
                        />
                    </div> */}
                    <div className="flex justify-center flex-col w-[155px] mx-auto">
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={redirectBookNowOrRescheduleHandler}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={RescheduleIcon}
                                    alt=""
                                    className="w-[20px]"
                                />
                            </div>
                            Reschedule
                        </button>
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={() => setCancelModal(true)}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={CancelIcon}
                                    alt=""
                                    className="w-[20px]"
                                />
                            </div>
                            Cancel
                        </button>
                    </div>
                </>
            )
        }

        // currentime is > booking time
        if (
            momentTimeFormat(currentTime) >
            momentTimeFormat(bookingDetails?.booking_session_time)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.35rem] font-ubuntu font-bold leading-8 text-center">
                        No Upcoming Session
                    </div>

                    <div className="flex justify-center w-[155px] mx-auto">
                        <button
                            type="button"
                            className={`bg-[#FFDBB0] mt-4 font-ubuntu rounded-[20px] font-semibold h-[40px] text-center text-[#F76411] block w-full ${bookingDetails?.booking_category_ctive === 0 ? 'cursor-no-drop' : 'cursor-pointer'}`}
                            // onClick={() => redirectBookNowOrRescheduleHandler()}
                            onClick={() => {
                                if ( bookingDetails?.booking_category_ctive === 0 ){
                                    return;
                                }
                                redirectBookNowOrRescheduleHandler()
                            }}
                        >
                            Book Now
                        </button>
                    </div>
                </>
            )
        }
    }

    // if currentDate & booked Date aren't same
    if (
        momentDateFormat(currentDate) !==
        momentDateFormat(bookingDetails?.booking_session_date)
    ) {
        // currentDate > bookedDate
        if (
            momentDateFormat(currentDate) >
            momentDateFormat(bookingDetails?.booking_session_date)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.35rem] font-ubuntu font-bold leading-8 text-center">
                        No Upcoming Session
                    </div>

                    <div className="flex justify-center w-[155px] mx-auto">
                        <button
                            type="button"
                            className={`bg-[#FFDBB0] mt-4 font-ubuntu rounded-[20px] font-semibold h-[40px] text-center text-[#F76411] block w-full ${bookingDetails?.booking_category_ctive === 0 ? 'cursor-no-drop' : 'cursor-pointer'}`}
                            // onClick={() => redirectBookNowOrRescheduleHandler()}
                            onClick={() => {
                                if ( bookingDetails?.booking_category_ctive === 0 ){
                                    return;
                                }
                                redirectBookNowOrRescheduleHandler()
                            }}
                        >
                            Book Now
                        </button>
                    </div>
                </>
            )
        }

        // currentDate < bookedDate
        if (
            momentDateFormat(currentDate) <
            momentDateFormat(bookingDetails?.booking_session_date)
        ) {
            content = (
                <>
                    <div className="w-full text-[1.3rem] font-ubuntu leading-8 text-center">
                        Upcoming Session
                    </div>
                    <div className="w-full pb-4 font-bold text-[1.2rem] font-ubuntu leading-8 text-center">
                        {moment(bookingDetails?.booking_session_date).format(
                            'DD/MM/YYYY'
                        )}{' '}
                        at {bookingDetails?.booking_session_time}hrs
                    </div>

                    {/* <div className="m-auto flex justify-center text-center pb-4">
                        <img
                            src={zoomIcon}
                            alt=""
                            className="border-[#FFFFFF] w-[50px] h-[50px] border-[2px] rounded-3xl"
                        />
                    </div> */}
                    <div className="flex justify-center flex-col w-[155px] mx-auto">
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={redirectBookNowOrRescheduleHandler}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={RescheduleIcon}
                                    alt=""
                                    className="w-[20px]"
                                />
                            </div>
                            Reschedule
                        </button>
                        <button
                            type="button"
                            className=" bg-[#FFDBB0] flex mt-4 font-ubuntu items-center rounded-[20px] font-semibold pl-3 h-[40px]"
                            onClick={() => setCancelModal(true)}
                        >
                            <div className="mt-0 mr-3">
                                <img
                                    src={CancelIcon}
                                    alt=""
                                    className="w-[20px]"
                                />
                            </div>
                            Cancel
                        </button>
                    </div>
                </>
            )
        }
    }

    // if status of booked session is cancelled or completed
    if (
        bookingDetails?.booking_status === 'cancelled' ||
        bookingDetails?.booking_status === 'completed'
    ) {
        content = (
            <>
                <div className="w-full text-[1.35rem] font-ubuntu font-bold leading-8 text-center">
                    No Upcoming Session
                </div>

                <div className="flex justify-center w-[155px] mx-auto">
                    <button
                        type="button"
                        className={`bg-[#FFDBB0] mt-4 font-ubuntu rounded-[20px] font-semibold h-[40px] text-center text-[#F76411] block w-full ${bookingDetails?.booking_category_ctive === 0 ? 'cursor-no-drop' : 'cursor-pointer'}`}
                        // onClick={() => redirectBookNowOrRescheduleHandler()}
                        onClick={() => {
                            if ( bookingDetails?.booking_category_ctive === 0 ){
                                return;
                            }
                            redirectBookNowOrRescheduleHandler()
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </>
        )
    }
    return (
        <aside className="bg-[#FFFFFF] border border-[#FFDBB0] rounded-2xl w-[250px] mt-3 p-4 text-center py-6 relative flex flex-col justify-center basis-2/4">
            {/* <ToastContainer hideProgressBar /> */}

            <div>
                {chatOpenLoading ? (
                    <img
                        src={loader}
                        className="w-10 h-10 my-1 mx-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                        alt="loader"
                    />
                ) : (
                    content
                )}

                {cancelModal && (
                    <CancelSlotModal
                        closeModal={() => setCancelModal(false)}
                        handleCancelReasonModal={handleCancelReasonModal}
                    />
                )}
                {cancelReasonModal && (
                    <CancelReasonModal
                        handleCancelReasonModal={handleCancelReasonModal}
                        cancelBookedSlotHandler={cancelBookedSlotHandler}
                        errMessage={errMessage}
                        setErrMessage={setErrMessage}
                        setBookingDetails={setBookingDetails}
                        bookingDetails={bookingDetails}
                    />
                )}
            </div>
        </aside>
    )
}

export default UpcomingSessions
