import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URL } from "../keys"

export const coachApi = createApi({
    reducerPath: "coach",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getCoaches: builder.mutation({
            query: (data) => ({
                url: 'client/list-coaches/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        bookSlot: builder.mutation({
            query: (data) => ({
                url: 'client/book-slot/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        getTimeSlots: builder.mutation({
            query: (data) => ({
                url: 'client/get-slots/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        cancelRelation: builder.mutation({
            query: (data) => ({
                url: 'client/book-slot/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        cancelBookedSlot: builder.mutation({
            query: (data) => ({
                url: 'client/cancel-booked-slot/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        giveRating: builder.mutation({
            query: (data) => ({
                url: 'client/star-rating-therapist/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        reportCoach: builder.mutation({
            query: (formData) => ({
                url: 'client/report-coach',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
        supportForm: builder.mutation({
            query: (formData) => ({
                url: 'common/support',
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('livtoken'),
                },
                body: formData,
            }),
        }),
    })
})

export const {
    useGetCoachesMutation,
    useBookSlotMutation,
    useGetTimeSlotsMutation,
    useCancelRelationMutation,
    useGiveRatingMutation,
    useCancelBookedSlotMutation,
    useReportCoachMutation,
    useSupportFormMutation
} = coachApi