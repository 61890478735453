import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper";

const Test = () => {


    return (
        <>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                spaceBetween={50}
                slidesPerView={4}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="w-[80vw] border-2 border-blue-600"
                style={{ clipPath: "polygon(17% 0, 100% 0, 100% 100%, 17% 100%)" }}
            >
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-1.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-2.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-3.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-4.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-5.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-6.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-7.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-8.jpg" />
                </SwiperSlide>
                <SwiperSlide className="w-[400px]">
                    <img className="w-[400px] object-contain" alt="nothing" src="https://swiperjs.com/demos/images/nature-9.jpg" />
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default Test