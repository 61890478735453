import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom'
import './App.css'
import 'react-calendar/dist/Calendar.css'
import FormScreen from './pages/FormScreen'
import Login from './pages/Login'
import Otp from './pages/Otp'
import AfterLogin from './pages/AfterLogin'
import Settings from './pages/Settings'
import Welcome from './pages/Client/Welcome'
import Profile from './pages/Client/Profile'
import Test from './pages/Client/Test'
import WelcomeCoach from './pages/Coach/Welcome'
import FindTherapist from './pages/Client/FindTherapist'
import Chats from './pages/Coach/Chats'
import Notes from './pages/Coach/Notes'
import Chat from './pages/Client/Chat'
import OTPverified from './pages/OTPverified'
import ProfileCoach from './pages/Coach/ProfileCoach'
import TermsConditionsPrivacy from './pages/TermsConditionsPrivacy'
import LandingPage from './pages/LandingPage'
import ReactGA from "react-ga"

const TRACKING_ID = "UA-260321077-2"
ReactGA.initialize(TRACKING_ID)

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Welcome />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/welcome"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Welcome />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/findtherapist/:id"
                    element={
                        <RequireAuth redirectTo="/login">
                            <FindTherapist />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/chat"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Chat />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/chat/:coach__id"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Chat />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/otpverified"
                    element={
                        <RequireAuth redirectTo="/login">
                            <OTPverified />
                        </RequireAuth>
                    }
                />
                {/* Coach Screens */}
                <Route
                    exact
                    path="/coach-welcome"
                    element={
                        <RequireAuth redirectTo="/login">
                            <WelcomeCoach />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-chat"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Chats />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-chat/:client__id"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Chats />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-notes"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Notes />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-notes/:clientId/:noteId"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Notes />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-notes/:clientId"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Notes />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/test"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Test />
                        </RequireAuth>
                    }
                />

                <Route
                    exact
                    path="/login"
                    element={
                        <NoAuth redirectTo="/">
                            <Login />
                        </NoAuth>
                    }
                />
                <Route
                    exact
                    path="/otp"
                    element={
                        <NoAuth redirectTo="/">
                            <Otp />
                        </NoAuth>
                    }
                />
                <Route
                    exact
                    path="/terms-conditions-privacy"
                    element={
                        // <NoAuth redirectTo="/terms-conditions-privacy">
                            <TermsConditionsPrivacy />
                        // </NoAuth>
                    }
                />
                <Route
                    exact
                    path="/home"
                    element={
                            <LandingPage />
                    }
                />

                <Route
                    exact
                    path="/after-login"
                    element={
                        <RequireAuth redirectTo="/login">
                            <AfterLogin />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/formscreen"
                    element={
                        <RequireAuth redirectTo="/login">
                            <FormScreen />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/settings"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Settings />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/profile"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/coach-profile"
                    element={
                        <RequireAuth redirectTo="/login">
                            <ProfileCoach />
                        </RequireAuth>
                    }
                />

                {/* new */}
                {/* <Route path='/home' component={() => {
                    window.location.href = 'https://www.hapsmiths.com/';
                    return null;
                }}/> */}
            </Routes>
        </Router>
    )
}

function RequireAuth({ children, redirectTo }) {
    const token = localStorage.getItem('livtoken')
    return token ? children : <Navigate to={redirectTo} />
}

function NoAuth({ children, redirectTo }) {
    const token = localStorage.getItem('livtoken')
    return !token ? children : <Navigate to={redirectTo} />
}

export default App
