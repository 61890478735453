import React, { useState, useRef } from 'react'
import call from '../../assets/images/call.png'
import mail from '../../assets/images/mail.png'
import Button from '../../UI/Button'
import { ToastContainer, toast } from 'react-toastify'
import { useSupportFormMutation } from '../../services/coachApi'

const Support = () => {

	const form = useRef(null)

	const [support, { isLoading }] = useSupportFormMutation();

	const [content, setContent] = useState('');

	const handleChange = event => {
		setContent(event.target.value);
	};

	const handleSupportForm = async (e) => {
		e.preventDefault();
		if (content.trim().length !== 0) {
			const formData = new FormData(form.current);
			const response = await support(formData);
			const data = await response.data;
			try {
				if (data.code === 'Success') {
					// toast.success(data.message);
					toast.success(data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					setContent({ content: '' })
				}
			} catch (error) {
				toast.error("Some error occured! Please try again.");
			}
		} else {
			toast.error('Please add your query and submit.');
		}
	}

	return (
		<section className="bg-white w-[65%] h-[75vh] rounded-[20px] py-6 px-12">
			<h1 className="text-2xl mb-[60px] text-black font-ubuntu font-bold">Support Hapsmiths</h1>
			<div className="w-[70%] mx-auto flex gap-8 mb-16 items-center">
				<img src={call} className="w-8 h-8 object-contain" alt="call" />
				<div>
					<p className="text-gray-500 font-constantia text-sm">Call us on</p>
					<span className="text-3xl font-bold">+91 8971829648</span>
				</div>
			</div>
			<div className="w-[70%] mx-auto flex gap-8 mb-8 items-center">
				<img src={mail} className="w-8 h-8 object-contain" alt="call" />
				<div>
					<p className="text-gray-500 font-constantia text-sm">Email us on</p>
					<span className="text-3xl font-bold">support@hapsmiths.com</span>
				</div>
			</div>
			<>
				<ToastContainer />
			</>
			<form ref={form} onSubmit={handleSupportForm} >
				<div className="w-[70%] mx-auto flex gap-4 mb-4 items-center">
					<div className="w-12 h-12"></div>
					<textarea name="content" onChange={handleChange} id="supportText" className="focus:outline-none bg-[#FDF5EE] border-2 border-[#FFDBB0] rounded-2xl p-3 placeholder:text-sm placeholder:text-gray-400 resize-none" placeholder="Share your query and we will get back at the earliest" cols="44" rows="4"></textarea>
				</div>
				<div className="w-[70%] mx-auto flex gap-4 items-center">
					<div className="w-12 h-12"></div>
					<Button type="submit" className="!bg-[#FFDBB0] w-1/2 font-bold font-ubuntu rounded-2xl text-[#F76411]">Submit</Button>
				</div>
			</form>
		</section>
	)
}

export default Support