import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookingId: null,
    coachId: null,
    // coachName: null
}

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {

        bookingError(state, action) {
            // console.log("BOOKING ERROR SLICE", action.payload)
            state.bookingId = action.payload.bookingId;
            state.coachId = action.payload.coachId;
        },
        // bookingErrorCoach(state, action) {
        //     console.log("BOOKING ERROR SLICE22222222", action.payload)
        //     state.coachName = action.payload.coachName;
        // }
    }
})

export const bookingActions = bookingSlice.actions;
export const bookingReducer = bookingSlice.reducer;

export default bookingSlice