import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    background: '',
    coachList: [],
    categoryIcon: '',
    categoryName: '',
    coachIdBN: '', //to get coachId when BookNow ot Reschedule btn from client chat page
    btnClick: 'no', //BookNow ot Reschedule btn from client chat page
}

export const therapistSlice = createSlice({
    name: 'therapistSlice',
    initialState,
    reducers: {
        setBG(state, action) {
            console.log('TherapistSlice', action.payload)
            state.background = action.payload
        },
        setCategoryIcon(state, action) {
            console.log('TherapistSlice Icon', action.payload)
            state.categoryIcon = action.payload
        },
        getCoachList(state, action) {
            state.coachList = action.payload
        },
        setCategoryName(state, action) {
            state.categoryName = action.payload
        },
        //Needed for storing coachId when redirected(OnCLick of BookNow and Reschedule) to FindTherapist page from Client Chat page
        setCoachIdBN(state, action) {
            state.coachIdBN = action.payload
        },
        setBtnClick(state, action) {
            state.btnClick = action.payload
        },
    },
})

export const therapistActions = therapistSlice.actions
export const therapistReducer = therapistSlice.reducer
