import React, { useEffect, useState } from 'react'
import zoomIcon from '../../../assets/images/zoom-icon.png'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import exclamationOrange from '../../../assets/images/exclamation-orange.png'
import { useSelector } from 'react-redux'
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import VideoJoinModal from '../Chat/Modals/VideoJoinModal';
import api from '../../../services/api';
import { timeDifferenceCalculator } from 'time-difference-calculator';
import ReactTimeAgo from 'react-time-ago'
import FeedbackCoach from '../../Client/FindTherapist/Popups/FeedbackCoach';
import ReportModal from '../Chat/Modals/ReportModal'
import { BASE_URL } from '../../../keys'

const NotesHeader = (selectedClientDetails) => {

    // console.log('selectedClientDetails from NotesHeader', selectedClientDetails)

    const [barStyle, setbarStyle] = useState("")
    const [countDown, setcountDown] = useState(null)
    const [videoModal, setvideoModal] = useState(false)
    const [videoLink, setvideoLink] = useState(null)
    const [feedback, setfeedback] = useState(false)
    const [modalDisplay, setModalDisplay] = useState(false)
    const [flaggedStatus, setFlaggedStatus] = useState("")
    const currentChatUser = useSelector(state => state.chatUserReducer.user)

    // console.log('currentChatUser from NotesHeader', currentChatUser);

    useEffect(() => {
        if (countDown?.days > 0) {
            return setbarStyle(`${100 / countDown.days}%`)
        } else if (countDown?.hour > 0) {
            return setbarStyle(`${100 / countDown.hour}%`)
        } else if (countDown?.days === 0) {
            return setbarStyle("100%")
        }
    }, [currentChatUser, countDown])

    const handleVideoCall = () => {
        if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') < 0) {
            toast.warn(`Your session is not started yet!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') > 0) {
            toast.warn(`Your session is completed!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') === 0) {
            if (Math.abs(moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.session_date + " " + currentChatUser?.session_time)), 'hours')) <= 1) {
                if (timeDifferenceCalculator(currentChatUser?.session_date + " " + currentChatUser?.session_time).split(" ")[0] <= 30) {
                    api.get(`common/get-booking-link/${currentChatUser?.last_message.booking_id}`, {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                        }
                    }).then((res) => {
                        //console.log(res, "RES&&")
                        setvideoLink(res.data.data.zoom_link)
                        //setvideoLink(null)
                    }).catch((err) => {
                        setfeedback(true)
                        // toast.warn(`Sorry some error occured!`, {
                        //     position: "top-right",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        //     theme: "light",
                        // });
                    })
                    setvideoModal(true)
                } else {
                    toast.warn(`Sorry not able to access the link!`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.warn(`Sorry not able to access the link!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const feedbackModal = () => {
        setTimeout(() => {
            setfeedback(true)
        }, 5000);
    }

    const feedbackClose = () => {
        setfeedback(false);
    }


    const DateTimeCal = (dateTime) => {

        const eventDate = Date.parse(dateTime);
        const eventCountdown = setInterval(() => {

            const now = new Date().getTime()
            const daysDivider = 1000 * 60 * 60 * 24
            const hourDivider = 1000 * 60 * 60
            const minutesDivider = 1000 * 60
            const secondsDivider = 1000


            const d = Math.floor(eventDate / daysDivider - now / daysDivider)
            const h = Math.floor((eventDate / hourDivider - now / hourDivider) % 24)
            const m = Math.floor((eventDate / minutesDivider - now / minutesDivider) % 60)
            const s = Math.floor((eventDate / secondsDivider - now / secondsDivider) % 60)

            const diff = eventDate - now
            if (diff > 0) {
                const countDownTemp = {
                    days: d,
                    hour: h,
                    minute: m,
                    second: s
                }
                setcountDown(countDownTemp)
                if (d > 0) {
                    return clearInterval(eventCountdown)
                }
                if (h > 0) {
                    return clearInterval(eventCountdown)
                }
                //console.log(`${d} Days  ${h} Hour ${m} Minute ${s} Seconds`)
            } else {
                // console.log("session completed")
                const countDownTemp = {
                    days: 0,
                    hour: 0,
                    minute: 0,
                    second: 0
                }
                setcountDown(countDownTemp)
                clearInterval(eventCountdown)
            }

        }, 1000)
    }

    return (
        <div className="flex border-b border-[#FFDBB0] pb-6">
            <div className="overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                {typeof selectedClientDetails?.selectedClientDetails !== 'undefined' ?
                    <img
                        src={`${selectedClientDetails?.selectedClientDetails?.user_profile_pic}` !== '' ?
                            `${BASE_URL}${selectedClientDetails?.selectedClientDetails?.user_profile_pic}` :
                            `${emptyavatar}`}
                        alt="profile image"
                        className=" w-[55px] h-[55px] rounded-full object-cover"
                    /> :
                    <img
                        src={`${emptyavatar}`}
                        alt="profile image"
                        className="w-[55px] h-[55px] rounded-full object-cover"
                    />
                }
            </div>
            <p className="w-[150px] pl-4 pt-4 font-semibold text-base">
                {selectedClientDetails?.selectedClientDetails?.user_name}
            </p>

            <div className="flex bg-[#FFDBB0] rounded-[40px] w-[250px] h-6 mt-4 ml-8">
                <div className="flex-1/2 w-[30px] h-[10px] bg-orange-500 rounded-[40px] mt-[7px] ml-3 z-10"></div>
                <div className="flex-1/2 w-[40px] h-[10px] bg-white rounded-[40px] mt-[7px] ml-[-6px] ">
                    <div className="bg-[#F76411] h-[10px] rounded-full" style={{ width: barStyle }}></div>
                </div>
                <p className="flex-1 font-semibold text-[13px] pl-5 pt-[2px]">
                    {
                        selectedClientDetails?.selectedClientDetails?.session_date ?
                            <ReactTimeAgo date={selectedClientDetails?.selectedClientDetails?.session_date + " " + selectedClientDetails?.selectedClientDetails?.session_time} locale="en-US" />
                            : null
                    }
                </p>
                <div className="flex-none cursor-pointer" onClick={handleVideoCall}>
                    <img src={zoomIcon} alt="" className="border-[#FFFFFF] w-[25px] h-[25px] border-[2px] rounded-3xl" />
                </div>
            </div>

            {/* <div className="pt-4 ml-14">
                <div className="bg-[#FFFFFF] w-[30px] rounded-[5px]">
                    <img onClick={() => setModalDisplay(true)} src={exclamationOrange} alt="" className="h-6 cursor-pointer" />
                </div>
            </div> */}

            {modalDisplay &&
                <ReportModal
                    currentChatUser={currentChatUser}
                    userFlaggedStatus={flaggedStatus}
                    onCloseModal={() => setModalDisplay(false)}
                />
            }
            {
                videoModal &&
                <VideoJoinModal feedbackModal={feedbackModal} onCloseModal={() => setvideoModal(false)} videoLink={videoLink} currentChatUser={currentChatUser} />
            }
            {
                feedback &&
                <FeedbackCoach feedbackClose={feedbackClose} />
            }
        </div>
    )
}

export default NotesHeader