import React from "react"

const Backdrop = (props) => {
  return (
    <div
      className={
        "bg-black/[.75] fixed top-0 left-0 w-full h-full z-[999] space-x-0" +
        props.className
      }
    />
  )
}

export default Backdrop
