import React from 'react'

const Left = () => {
  return (
    <div className="font-constantia w-1/2 xs:w-full xs:mb-[-130px] md:w-full sm:w-full flex justify-center items-center">

    <div className="w-9/12 xs:w-full md:w-full h-[30vh] xs:h-[10vh] md:h-[15vh]">
        <h1 className="text-5xl leading-[55px] xs:leading-[40px] xs:text-3xl">
            Let's get to know <br className='xs:hidden' /> you... Introduce <br className='xs:hidden' /> yourself
        </h1>
       
    </div>

</div>
  )
}

export default Left