import React, { useState, useEffect } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import searchIcon from '../../../assets/images/search-icon.png'
import chatIcon from '../../../assets/images/chat.png'
import moment from 'moment'
import { clientData } from './dummyClientData'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../../keys'

const ClientNoteList = ({
    clientListing,
    selectedClient,
    toggleUser,
    clientListingLoading,
    clientListingSuccess,
}) => {

    // console.log('Chat List from Coach`s ClientNoteList Page', clientListing)

    const navigate = useNavigate()

    const [searchClient, setSearchClient] = useState('')
    const [leftClientList, setLeftClientList] = useState(clientListing)

    useEffect(() => {
        const filteredClientList = clientListing?.filter((client) =>
            client?.user_name.toLowerCase().includes(searchClient.toLowerCase())
        )

        setLeftClientList(filteredClientList)
    }, [clientListing, searchClient])

    const getClientChats = (client_id) => {
        navigate(`/coach-chat/${client_id}`)
    }

    return (
        <aside className="w-[350px] p-4 h-[92%] mt-12 ml-8">
            <h1 className="text-xl">Notes</h1>

            {/* Text Search */}
            <div className="flex bg-white rounded-3xl py-2 px-4 outline-none border-[#FFC289] border mt-3">
                <div className="w-[30px] mt-1">
                    <img src={searchIcon} alt="" className="h-5" />
                </div>
                <input
                    className="outline-none w-full"
                    name="search-text"
                    id="search-text"
                    placeholder="Search"
                    onChange={(e) => setSearchClient(e.target.value)}
                />
            </div>

            {/* Client Loop */}
            <SimpleBar className="h-[78vh] w-full">
                <div className="w-full">
                    {clientListingLoading && (
                        <>
                            {clientData.map((_, i) => (
                                <div
                                    key={i}
                                    className="border bg-heavy border-heavyBorder shadow rounded-md p-4 max-w-sm w-full mx-auto my-3"
                                >
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="rounded-full bg-heavyBorder h-10 w-10"></div>
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="h-2 bg-heavyBorder rounded"></div>
                                            <div className="space-y-3">
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                    <div className="h-2 bg-heavyBorder rounded col-span-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {!clientListingLoading && leftClientList?.length === 0 && (
                        <p className="mt-40 text-center">No Clients Found!</p>
                    )}
                    {!clientListingLoading &&
                        leftClientList?.map((client, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${client.user_id === selectedClient
                                        ? 'bg-white'
                                        : 'bg-transparent'
                                        } flex rounded-[40px] w-full py-4 px-4 mt-5 cursor-pointer`}
                                    onClick={() => toggleUser(client.user_id)}
                                >
                                    <div className="relative overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                                        <img
                                            src={`${client.user_profile_pic}` !== '' ?
                                                `${BASE_URL}${client.user_profile_pic}` :
                                                `${emptyavatar}`}
                                            alt="profile image"
                                            className="w-[55px] h-[55px] rounded-full object-cover"
                                        />
                                    </div>
                                    <div className="pl-3 w-[150px]">
                                        <p className="text-sm font-medium">
                                            {client.user_name}
                                        </p>
                                        <p className="text-xs text-gray-500">
                                            Last edited
                                        </p>
                                        <p className="text-[11px] font-bold">
                                            {moment(client.updated_at).format('DD/MM/YYYY')}
                                        </p>
                                    </div>
                                    <img
                                        className="w-[25px] h-[25px] !mt-[-5px] absolute right-8 flex-1"
                                        src={chatIcon}
                                        alt="Chat"
                                        onClick={() => getClientChats(client.user_id)}
                                    />
                                    <div className="mt-9">
                                        <p className="text-[11px] font-bold text-right">
                                            {moment(
                                                client.updated_at,
                                                'HH:mm:ss'
                                            ).format('hh:mma')}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </SimpleBar>
        </aside>
    )
}

export default ClientNoteList
