const sixtymins =

	[{
		time: '00:00',
		event: null
	},
	{
		time: '01:00',
		event: null
	},
	{
		time: '02:00',
		event: null
	},
	{
		time: '03:00',
		event: null
	},
	{
		time: '04:00',
		event: null
	},
	{
		time: '05:00',
		event: null
	},
	{
		time: '06:00',
		event: null
	},
	{
		time: '07:00',
		event: null
	},
	{
		time: '08:00',
		event: null
	},
	{
		time: '09:00',
		event: null
	},
	{
		time: '10:00',
		event: null
	},
	{
		time: '11:00',
		event: null
	},
	{
		time: '12:00',
		event: null
	},
	{
		time: '13:00',
		event: null
	},
	{
		time: '14:00',
		event: null
	},
	{
		time: '15:00',
		event: null
	},
	{
		time: '16:00',
		event: null
	},
	{
		time: '17:00',
		event: null
	},
	{
		time: '18:00',
		event: null
	},
	{
		time: '19:00',
		event: null
	},
	{
		time: '20:00',
		event: null
	},
	{
		time: '21:00',
		event: null
	},
	{
		time: '22:00',
		event: null
	},
	{
		time: '23:00',
		event: null
	}];

export default sixtymins