import React, { useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import zoomIcon from '../../../assets/images/zoom-icon.png'
import exclamationOrange from '../../../assets/images/exclamation-orange.png'
import NoteItem from './NoteItem'
import { useDispatch, useSelector } from 'react-redux'
import { useGetClientNotesListQuery } from '../../../services/coachScreenApi'
import { clientData } from './dummyClientData'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import api from '../../../services/api'
import { setNote, setPinnedNote } from '../../../services/noteSlice'
import ReportModal from '../Chat/Modals/ReportModal'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import NotesHeader from './NotesHeader'
import { BASE_URL } from '../../../keys'

const NoteList = ({ clientListing, selectedClient, getNewNote, handlePandUp, setEditNote }) => {

    const dispatch = useDispatch();
    const [newNotes, setNewNotes] = useState(false)

    const [editNoteId, setEditNoteId] = useState(null);
    const [loading, setLoading] = useState(false)
    const [todayNote, setTodayNote] = useState(null);

    // console.log('clientListing-----------------------------------', clientListing);

    const selectedClientDetails = clientListing?.find(
        (client) => client.user_id === selectedClient
    )

    const { notes } = useSelector((state) => state.notes)

    const [modalDisplay, setModalDisplay] = useState(false);
    const currentChatUser = useSelector(state => state.chatUserReducer.user);

    // notes.length
    useEffect(() => {
        // console.log('notes from NoteList1', notes);
        // console.log('notes from NoteList2', Object.keys(notes).length); 
        if (typeof notes !== 'undefined') {
            if (Object.keys(notes).length == 0) {
                setLoading(true)
            }
        }
    }, [notes])

    useEffect(() => {
        getNewNote(newNotes)
    }, [newNotes])

    useEffect(() => {
        setEditNote(editNoteId)
    }, [editNoteId])

    const handlePinnedUnpinned = ({ noteId, isPinned, clientId }) => {
        // console.log(noteId, isPinned, clientId, "details in pinned upinned");
        api.get(`employee/make-note-pinned/${noteId}/${isPinned}/${clientId}/`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            dispatch(setPinnedNote({ noteId, isPinned, clientId }))
        }).catch((err) => {
            // console.log(err, "error in pinnedUnpinned")
            toast.warn(`${err.response?.data?.data?.error}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }

    useEffect(() => {
        listNotes();
    }, [selectedClient])

    const listNotes = () => {
        setLoading(true);
        api.get(`employee/get-all-notes/${selectedClient}/`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            // console.log('res.data.data.notes', res?.data?.data?.notes);

            // if (res?.data?.data?.notes.includes('2022-11-21')) {
            //     console.log('array contains apple', moment().format("YYYY-MM-DD HH:mm"));
            // }

            const isFound = res?.data?.data?.notes.some(element => {
                // console.log('element', element.created_at);
                // console.log('array contains apple', moment().format("YYYY-MM-DD"));
                if (element.created_at.includes(moment().format("YYYY-MM-DD"))) {
                    if (element.id !== '') {
                        setTodayNote(element.id)
                    }
                }
                // return false;
            });

            // if (isFound) {
            //     console.log('New note is there for today');
            // }



            // console.log(
            //     res?.data?.data?.notes.filter((date) => res?.data?.data?.notes == '')
            //     )
            // moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.session_date)), 'days')
            dispatch(setNote(res?.data?.data?.notes));
            setLoading(false)
        })
    }

    const createNote = () => {
        // console.log('todayNote', todayNote)
        setNewNotes(true)
        setEditNote(todayNote)
    }

    // console.log('selectedClientDetails.length', Object.keys(selectedClientDetails).length);

    return (
        <aside className="bg-white relative rounded-2xl w-[45%] p-4 h-[92%] mt-12">

            {/* Header */}
            <NotesHeader selectedClientDetails={selectedClientDetails} />


            {/* <div className="flex border-b border-[#FFDBB0] pb-6">
                <div className="overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                    {typeof selectedClientDetails !== 'undefined' ?
                        <img
                            // src={`${BASE_URL}${selectedClientDetails?.user_profile_pic}` || `${emptyavatar}`}
                            src={`${selectedClientDetails.user_profile_pic}` !== '' ?
                                `${BASE_URL}${selectedClientDetails.user_profile_pic}` :
                                `${emptyavatar}`}
                            alt="profile image"
                            className=" w-[55px] h-[55px] rounded-full"
                        /> :
                        <img
                            src={`${emptyavatar}`}
                            alt="profile image"
                            className="w-[55px] h-[55px] rounded-full"
                        />
                    }
                </div>
                <p className="w-[150px] pl-4 pt-4 font-semibold text-base">
                    {selectedClientDetails?.user_name}
                </p>

                <div className="flex bg-[#FFDBB0] rounded-[40px] w-[250px] h-6 mt-4 ml-8">
                    <div className="flex-1/2 w-[30px] h-[10px] bg-orange-500 rounded-[40px] mt-[7px] ml-3 z-10"></div>
                    <div className="flex-1/2 w-[40px] h-[10px] bg-white rounded-[40px] mt-[7px] ml-[-6px]"></div>
                    <p className="flex-1 font-semibold text-[13px] pl-5 pt-[2px]">
                        <span className="font-bold text-[13px]">2 days</span>{' '} remaining
                    </p>
                    <div className="flex-none">
                        <img
                            src={zoomIcon}
                            alt=""
                            className="border-[#FFFFFF] w-[25px] h-[25px] border-[2px] rounded-3xl"
                        />
                    </div>
                </div>

                {/* <div className="pt-4 ml-14">
                    <div className="bg-[#FFFFFF] w-[30px] rounded-[5px]">
                        <img onClick={() => setModalDisplay(true)} src={exclamationOrange} alt="" className="h-6 cursor-pointer" />
                    </div>
                </div>

                {modalDisplay &&
                    <ReportModal
                        // currentChatUser={currentChatUser}
                        // userFlaggedStatus={flaggedStatus}
                        onCloseModal={() => setModalDisplay(false)}
                    />
                }
            </div> */}

            {/* NoteList */}
            <SimpleBar className='lg:h-[40vh] xl:h-[60vh] 2xl:h-[75vh]' forceVisible="y" autoHide={false}>
                <div className="w-full mt-6 grid grid-cols-3 gap-4">
                    {loading && (
                        <>
                            <div className="text-l pb-2 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                No notes added yet!
                            </div>
                            {/* {clientData.map((_, i) => (
                                <div
                                    key={i}
                                    className="border bg-heavy border-heavyBorder shadow p-4 max-w-sm mx-auto rounded-[20px] w-[175px] min-h-[150px] max-h-[150px]"
                                >
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="h-2 bg-heavyBorder rounded w-20"></div>
                                            <div className="space-y-3">
                                                <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))} */}
                        </>
                    )}
                    {!loading &&
                        notes?.map((item, index) => (
                            <NoteItem
                                key={index}
                                item={item}
                                selectedClient={selectedClient}
                                handlePinnedUnpinned={handlePinnedUnpinned}
                                setNewNotes={setNewNotes}
                                setEditNoteId={setEditNoteId}
                                listNotes={listNotes}
                            />
                        ))
                    }
                </div>
            </SimpleBar>

            {/* Footer */}

            <div
                onClick={() => createNote()}
                className="flex justify-center items-center border w-[45px] h-[45px] mt-5 rounded-full bg-[#FDE9D8] border-[#FFDBB0] cursor-pointer"
                title="Add a new note"
            >
                <span className="text-3xl text-orange-500 mt-[-5px]  ">+</span>
            </div>
            <ToastContainer />
        </aside >
    )
}

export default NoteList
