import React, { useState, useEffect } from 'react'

const Countdown = ({ counter, setCounter }) => {
    // const [counter, setCounter] = useState(timer)

    useEffect(() => {
        if (counter > 0) {
            // setTimeout(() => setCounter(counter - 1), 1000)
            setTimeout(() => {
                setCounter(counter - 1)
                localStorage.setItem('OTP_TIMER', counter - 1)
            }, 1000)
        }

        // return () => {
        //     localStorage.removeItem('OTP_TIMER')
        // }
    }, [counter])

    return <span>in {counter}s</span>
}

export default Countdown
