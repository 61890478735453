import React, { useRef } from 'react'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import zoomIcon from '../../../assets/images/zoom-icon.png'
import flaggedIcon from '../../../assets/images/flag-orange.png'
import exclamationOrange from '../../../assets/images/exclamation-orange.png'
import sendButton from '../../../assets/images/send-button.png'
import Header from './Conversation/Header';
import { useOpenChatMutation, useSendMessageMutation } from '../../../services/chatApi';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useCallback } from 'react';
import moment from 'moment';
import { BASE_URL } from '../../../keys';
import ReportModal from '../Chat/Modals/ReportModal'
import loader from '../../../assets/images/loader.gif'
import Button from '../../../UI/Button';
import RestrictIcon from '../../../assets/images/restrict-icon.png'
import { ToastContainer, toast } from 'react-toastify';
import VideoJoinModal from './Modals/VideoJoinModal';
import api from '../../../services/api';
import { timeDifferenceCalculator } from 'time-difference-calculator';
import datetimeDifference from "datetime-difference";
import ReactTimeAgo from 'react-time-ago'
import FeedbackCoach from '../../Client/FindTherapist/Popups/FeedbackCoach';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import HTMLReactParser from 'html-react-parser'

function checkForLinkInText(text) {
    // let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
		// let urlRegex1 = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
		// let urlRegex2 = /(https?:\/\/[^\s]+)/g;
		let urlRegex3 = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    return text.replace(urlRegex3, function(url) {
        return '<a href="' + url + '" target="_blank" className="underline">' + url + '</a>';
    });
}

const Conversation = ({ socket }) => {

    const [modalDisplay, setModalDisplay] = useState(false);
    const [openChat, { isLoading: chatOpenLoading, isSuccess: chatOpenSuccess }] = useOpenChatMutation();
    const [sendMessage, { isLoading: sendMessageLoading, isError: sendMessageError }] = useSendMessageMutation();
    const [pageNo, setPageNo] = useState(1);
    const currentChatUser = useSelector(state => state.chatUserReducer.user);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [fileURL, setFileURL] = useState(null);
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const roomId = useSelector((state) => state.client.roomId);
    const [barStyle, setbarStyle] = useState("");
    const [videoModal, setvideoModal] = useState(false);
    const [videoLink, setvideoLink] = useState(null);
    const [feedback, setfeedback] = useState(false);
    const [flaggedStatus, setFlaggedStatus] = useState("");
    const [countDown, setcountDown] = useState(null);
    const isReported = localStorage.getItem('isReported');
    const [visible, setVisible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false);
    const msgStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const sendFileRef = useRef(null);

    useEffect(() => {
        setFlaggedStatus(isReported)
    }, [isReported])

    const fetchMessages = async (page, userChanged = false) => {
        const formData = new FormData();
        formData.append('person_id', currentChatUser?.user_id);
        formData.append('page_no', page);

        try {
            const response = await openChat(formData);
            // console.log('response?.data?.data', response?.data?.data);
            setFlaggedStatus(response.data.is_user_flagged);
            if (userChanged) {
                setHideShowMoreBtn(false)
                setMessages(response?.data?.data);
                scrollToBottom();
            } else {
                if (response?.data?.data.length == 0) {
                    setHideShowMoreBtn(true)
                }
                setMessages([...response?.data?.data, ...messages])
                scrollToTop();
            }
            localStorage.setItem('livBookingId', response?.data?.data.at(-1).booking_id)

        } catch (error) {

            // toast.error(`Sorry, no other messages to show!`, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
        }
        return messages;
    }

    const chatOpenHandler = useCallback(async (page, userChanged = true) => {
        fetchMessages(page, userChanged)
    }, [currentChatUser?.user_id]);

    // const chatOpenHandler = useCallback(async (page) => {
    //     const formData = new FormData();
    //     formData.append('person_id', currentChatUser?.user_id);
    //     formData.append('page_no', page);

    //     // prevent page from going all blank
    //     try {
    //         const response = await openChat(formData);
    //         // console.log('-----------------------openChat.data from coach side', response.data);
    //         setFlaggedStatus(response.data.is_user_flagged);
    //         // setMessages(response?.data?.data);
    //         if (response?.data?.data) {
    //             setMessages([...response?.data?.data, ...messages])
    //         }
    //         localStorage.setItem('livBookingId', response?.data?.data.at(-1).booking_id)

    //     } catch (error) {
    //         console.log(error.message)
    //     }
    // }, [openChat, pageNo, currentChatUser?.user_id]);

    // console.log('---------------messages', messages)

    // useEffect(() => {
    //     chatOpenHandler(pageNo);
    // }, [currentChatUser?.user_name, pageNo]);

    useEffect(() => {
        setPageNo(1);
        chatOpenHandler(1, true);
        setErrorMessage("");
    }, [currentChatUser?.user_id, fileURL])


    useEffect(() => {
        setIsOpen(false)
        setFileURL(null)
    }, [currentChatUser])

    const handlePageChange = () => {
        setPageNo(prev => prev + 1);
        fetchMessages(pageNo + 1);
    }

    const scrollToTop = () => {
        // console.log('scrollToTop triggered');
        msgStartRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
    }

    useEffect(() => {
        socket.on("receive_message", (data) => {
            // console.log("message received************")
            setMessages((prev) => ([...prev, data]))
            // scrollToBottom()
            // chatOpenHandler(pageNo);
        })
    }, [socket])

    // console.log(currentChatUser, "currentChatUser in Coach page");

    const sendMessageHandler = async () => {
        // console.log('Coach Chat');
        if (currentMessage !== "" || file) {
            const messageData = {
                room: roomId,
                //from_user_id: user.user_id,
                message: currentMessage,
                booking_id: localStorage.getItem('livBookingId'),
                time: new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes(),
                file_link: fileURL,
            }
            await socket.emit("send_message", messageData);

            const formData = new FormData();
            formData.append('message_from', currentChatUser?.coach_id);
            formData.append('message_to', currentChatUser?.user_id);
            formData.append('booking_id', localStorage.getItem('livBookingId'));
            formData.append('message', currentMessage);
            formData.append('message_file', file);
            setMessages((prev) => ([...prev, messageData]))
            const response = await sendMessage(formData);

            // chatOpenHandler(pageNo);

            scrollToBottom() //this happens when we add a chat and enter
            setCurrentMessage("")
            setFileURL(null);
            setFile(null);
            setIsOpen(false);

            if (response.error) {
                setErrorMessage(response.error.data.data.booking_id[0] || "Something went wrong! Please try again");
                // chatOpenHandler();
            }

        }
    }

    useEffect(() => {

        if (countDown?.days > 0) {
            return setbarStyle(`${100 / countDown.days}%`)
        } else if (countDown?.hour > 0) {
            return setbarStyle(`${100 / countDown.hour}%`)
        } else if (countDown?.days === 0) {
            return setbarStyle("100%")
        }

        // scrollToBottom()
    }, [currentChatUser, countDown])

    // console.log(barStyle, "barStyle****")

    useEffect(() => {
        DateTimeCal(currentChatUser?.session_date + " " + currentChatUser?.session_time)
        // scrollToBottom() // commented this to avoid scroll to bottom when load previous is clicked
    }, [messages, currentChatUser])

    const handleVideoCall = () => {
        if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') < 0) {
            toast.warn(`Your session is not started yet!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') > 0) {
            toast.warn(`Your session is completed!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.session_date)), 'days') === 0) {
            if (Math.abs(moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(currentChatUser?.session_date + " " + currentChatUser?.session_time)), 'hours')) <= 1) {
                if (timeDifferenceCalculator(currentChatUser?.session_date + " " + currentChatUser?.session_time).split(" ")[0] <= 30) {
                    api.get(`common/get-booking-link/${currentChatUser?.last_message.booking_id}`, {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                        }
                    }).then((res) => {
                        // console.log(res, "RES&&")
                        setvideoLink(res.data.data.zoom_link)
                        // setvideoLink(null)
                    }).catch((err) => {
                        setfeedback(true)
                        // toast.warn(`Sorry some error occured!`, {
                        //     position: "top-right",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        //     theme: "light",
                        // });
                    })
                    setvideoModal(true)
                } else {
                    toast.warn(`Sorry not able to access the link!`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.warn(`Sorry not able to access the link!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const feedbackModal = () => {
        setTimeout(() => {
            setfeedback(true)
        }, 5000);
    }

    const feedbackClose = () => {
        setfeedback(false);
    }

    const DateTimeCal = (dateTime) => {

        const eventDate = Date.parse(dateTime);
        const eventCountdown = setInterval(() => {

            const now = new Date().getTime()
            const daysDivider = 1000 * 60 * 60 * 24
            const hourDivider = 1000 * 60 * 60
            const minutesDivider = 1000 * 60
            const secondsDivider = 1000


            const d = Math.floor(eventDate / daysDivider - now / daysDivider)
            const h = Math.floor((eventDate / hourDivider - now / hourDivider) % 24)
            const m = Math.floor((eventDate / minutesDivider - now / minutesDivider) % 60)
            const s = Math.floor((eventDate / secondsDivider - now / secondsDivider) % 60)

            const diff = eventDate - now
            if (diff > 0) {
                const countDownTemp = {
                    days: d,
                    hour: h,
                    minute: m,
                    second: s
                }
                setcountDown(countDownTemp)
                if (d > 0) {
                    return clearInterval(eventCountdown)
                }
                if (h > 0) {
                    return clearInterval(eventCountdown)
                }
                // console.log(`${d} Days  ${h} Hour ${m} Minute ${s} Seconds`)
            } else {
                // console.log("session completed")
                const countDownTemp = {
                    days: 0,
                    hour: 0,
                    minute: 0,
                    second: 0
                }
                setcountDown(countDownTemp)
                clearInterval(eventCountdown)
            }

        }, 1000)
    }

    const removeElement = () => {
        setFileURL(null);
        setFile(null);
        setIsOpen(false)
        // setVisible((prev) => !prev);
    };

    return (
        <aside className="bg-white relative rounded-2xl w-[45%] p-4 h-[92%] mt-12">
            {/* Header */}
            <div className="flex border-b border-[#FFDBB0] pb-6">
                {/* <Header /> */}
                <Header currentChatUser={currentChatUser} />

                <div className="flex bg-[#FFDBB0] rounded-[40px] w-[250px] h-6 mt-4 ml-8">
                    <div className="flex-1/2 w-[30px] h-[10px] bg-orange-500 rounded-[40px] mt-[7px] ml-3 z-10"></div>
                    <div className="flex-1/2 w-[40px] h-[10px] bg-white rounded-[40px] mt-[7px] ml-[-6px] ">
                        <div className="bg-[#F76411] h-[10px] rounded-full" style={{ width: barStyle }}></div>
                    </div>
                    <p className="flex-1 font-semibold text-[13px] pl-5 pt-[2px]">
                        {
                            currentChatUser?.session_date ?
                                <ReactTimeAgo date={currentChatUser?.session_date + " " + currentChatUser?.session_time} locale="en-US" />
                                : null
                        }
                    </p>
                    <div className="flex-none cursor-pointer" onClick={handleVideoCall}>
                        <img src={zoomIcon} alt="" className="border-[#FFFFFF] w-[25px] h-[25px] border-[2px] rounded-3xl" />
                    </div>
                </div>

                <p className="flex pt-4 ml-14">
                    {flaggedStatus != 0 &&
                        <div><img src={flaggedIcon} alt="flaggedIcon" className="mr-2 h-6" title='This client is flagged' /></div>
                    }
                    <div className="bg-[#FFFFFF] w-[30px] rounded-[5px]">
                        <img onClick={() => setModalDisplay(true)} src={exclamationOrange} alt="" className="h-6 cursor-pointer" />
                    </div>
                </p>

                {modalDisplay &&
                    <ReportModal
                        currentChatUser={currentChatUser}
                        userFlaggedStatus={flaggedStatus}
                        onCloseModal={() => setModalDisplay(false)}
                    />
                }
                {
                    videoModal &&
                    <VideoJoinModal feedbackModal={feedbackModal} onCloseModal={() => setvideoModal(false)} videoLink={videoLink} currentChatUser={currentChatUser} />
                }
                {
                    feedback &&
                    <FeedbackCoach feedbackClose={feedbackClose} />
                }
            </div>

            {/* Conversation */}
            <SimpleBar className='lg:h-[40vh] xl:h-[60vh] 2xl:h-[70vh]' forceVisible="y" autoHide={false}>
                <div className="w-full mt-6  pt-4 pb-4 px-10">

                    <div ref={msgStartRef} />
                    {chatOpenLoading && !chatOpenSuccess &&
                        <img src={loader} className="w-48 h-48 mx-auto my-auto" alt="" />
                    }
                    {!chatOpenLoading && chatOpenSuccess &&
                        <>
                            {/* {console.log('hideShowMoreBtn', hideShowMoreBtn)} */}
                            {!hideShowMoreBtn &&
                                <div className="flex w-full justify-center my-2 gap-2">

                                    {messages?.length > 0 &&
                                        <Button title="Load Previous"
                                            onClick={() => handlePageChange()}
                                            className="bg-heavy font-bold font-ubuntu text-lightOrange px-4">👆</Button>
                                    }
                                </div>
                            }

                            {messages?.length === 0 &&
                                <div className="w-full h-auto my-auto flex justify-center items-center">
                                    <p className="text-1 font-bold font-ubuntu">No messages to show!</p>
                                </div>
                            }

                            {
                                (messages?.filter((d) => moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(d.created_at)), 'days') != 0
                                    && moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(d.created_at)), 'days') != 1))?.map((item, index) => {
                                        return (
                                            <div
                                                id={'mess' + index}
                                                key={item?.created_at ?? index}

                                                style={{
                                                    marginRight: item.from_user_id == currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit",
                                                    marginLeft: item.from_user_id != currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit"
                                                }}
                                                className={`${item.message_type === "st" ?
                                                    "w-max max-w-[85%] relative text-sm rounded-[40px] py-3 px-5" :
                                                    item.message_type === "div" ? " relative text-sm rounded-[40px] py-3 px-5" :
                                                        item?.file_link?.length > 2 ? "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2  bg-[#fdf5ee]" : " w-max max-w-[70%] relative text-sm rounded-[40px] py-3 px-5"}`}
                                            >
                                                {item?.file_link?.length > 2 &&
                                                    <img src={(BASE_URL + item?.file_link)} className="w-full mb-3 rounded-[40px]" alt="" />
                                                }

                                                {/* For displaying the date-wise division */}
                                                {
                                                    item.message_type === "div" &&
                                                    <div className="flex flex-row items-center">
                                                        <div className="text-[#868686] font-ubuntu text-xs !ml-[-20px] w-24">
                                                            {HTMLReactParser(checkForLinkInText(`${item.message}`))}
                                                        </div>
                                                        <div className="!w-full">
                                                            <div className="border-t-[2px] border-[#FFE2C0] ml-[10px]"></div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* For displaying the status of message */}
                                                {
                                                    item.message_type === "st" &&
                                                    <div className="flex bg-[#fdf5ee] rounded-3xl p-2 mb-3 border-[#FFDBB0] border-[1px]">
                                                        <span className="w-[80%] text-[13px] pl-2 leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
                                                        <span className="w-[20%] absolute right-8 bottom-6 pt-2 text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
                                                    </div>
                                                }

                                                {
                                                    (item?.message_type === "st" || item?.message_type === "div") ? null :
                                                        <div className="w-full flex bg-[#fdf5ee] rounded-3xl p-2">
                                                            <div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</div>
                                                            <div className="flex-none absolute right-8 bottom-3 whitespace-nowrap align-text-bottom text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</div>
                                                        </div>
                                                }

                                            </div>
                                        )
                                    })
                            }




                            {/* Yesterday chat */}
                            {
                                messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 1).length > 0 ?
                                    <div className="flex flex-row items-center">
                                        <div className="text-[#868686] font-ubuntu text-xs">
                                            Yesterday
                                        </div>
                                        <div className="w-full">
                                            <div className="border-t-[2px] border-[#FFE2C0] ml-[10px]"></div>
                                        </div>

                                    </div> : null
                            }
                            {
                                (messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 1))?.map((item, index) => {
                                    return (
                                        <div
                                            id={'mess' + index}
                                            key={item?.created_at ?? index}

                                            style={{
                                                marginRight: item.from_user_id == currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit",
                                                marginLeft: item.from_user_id != currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit"
                                            }}
                                            className={`${item.message_type === "st" ? " w-max max-w-[85%] relative text-sm rounded-[40px] py-3 px-5" : " w-max max-w-[70%] relative text-sm rounded-[40px] py-3 px-5"}`}>

                                            {item?.file_link?.length > 2 &&
                                                <img src={(BASE_URL + item?.file_link)} className="w-full my-3 rounded-[40px]" alt="" />
                                            }
                                            {/* For displaying the status of message */}
                                            {
                                                item.message_type === "st" &&
                                                <div className="flex bg-[#fdf5ee] rounded-3xl p-2 mb-3 border-[#FFDBB0] border-[1px]">
                                                    <span className="w-[80%] text-[13px] pl-2 leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
                                                    <span className="w-[20%] absolute right-8 bottom-6 pt-2 text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
                                                </div>
                                            }
                                            {
                                                (item?.message_type === "st" || item?.message_type === "div") ? null :
                                                    <div className="w-full flex bg-[#fdf5ee] rounded-3xl p-2">
                                                        <div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</div>
                                                        <div className="flex-none absolute right-8 bottom-3 whitespace-nowrap align-text-bottom text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</div>
                                                    </div>
                                            }
                                        </div>
                                    )
                                })
                            }


                            {/* Today messages */}
                            {
                                messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 0).length > 0 ?
                                    <div className="flex flex-row items-center my-2">
                                        <div className="text-[#868686] font-ubuntu text-xs">
                                            Today
                                        </div>
                                        <div className="w-full">
                                            <div className="border-t-[2px] border-[#FFE2C0] ml-[10px]"></div>
                                        </div>
                                    </div> : null
                            }
                            {
                                (messages?.filter((d) => moment(moment().format("YYYY-MM-DD HH:mm")).diff(moment(moment().format(d.created_at)), 'days') === 0))?.map((item, index) => {
                                    return (
                                        <div
                                            id={'mess' + index}
                                            key={item?.created_at ?? index}
                                            style={{
                                                marginRight: item.from_user_id == currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit",
                                                marginLeft: item.from_user_id != currentChatUser?.user_id || item.message_type === "st" ? "auto" : "inherit"
                                            }}

                                            className={`${item.message_type === "st" ?
                                                "w-max max-w-[85%] flex-wrap relative text-sm rounded-[40px] mb-3  p-2" :
                                                item?.file_link?.length > 2 ? "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2  bg-[#fdf5ee]" :
                                                    "w-max max-w-[70%] relative text-sm rounded-[40px] mb-3 p-2"}`}
                                        >


                                            {item?.file_link?.length > 2 &&
                                                <img src={(BASE_URL + item?.file_link)} className="w-full my-3 rounded-[40px]" alt="" />
                                            }
                                            {/* For displaying the status of message */}
                                            {
                                                item.message_type === "st" &&
                                                <div className="flex bg-[#fdf5ee] rounded-3xl p-2 mb-3 border-[#FFDBB0] border-[1px]">
                                                    <span className="w-[80%] text-[13px] pl-2 leading-4 text-[#868686] italic">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</span>
                                                    <span className="w-[20%] absolute right-8 bottom-6 pt-2 text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</span>
                                                </div>
                                            }
                                            {
                                                (item?.message_type === "st" || item?.message_type === "div") ? null :
                                                    <div className="w-full flex bg-[#fdf5ee] rounded-3xl p-2">
                                                        <div className="flex-1 text-[13px] left-0 !pr-8 !mr-8 leading-4 break-all">{HTMLReactParser(checkForLinkInText(`${item.message}`))}</div>
                                                        <div className="flex-none absolute right-8 bottom-3 whitespace-nowrap align-text-bottom text-right text-gray-400 text-[10px]">{moment(item.created_at).format('hh:mm a')}</div>
                                                    </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                            <div ref={messagesEndRef}></div>
                        </>
                    }

                    {/* moment(item.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') */}

                    {/* Division for Today
                    <div className="flex my-6">
                        <span className="flex-0 text-xs text-gray-400 w-[10%]">Today</span>
                        <div className="flex-1 h-0 mt-2 border-t-[1px] border-[#FFE2C0] w-[90%]"></div>
                    </div>

                    {/* Schedule details */}
                    {/* <div className="flex relative bg-[#FDF5EE] text-sm rounded-[40px] mb-4 py-2 px-5 w-[90%] m-auto border border-[#FFDBB0]">
                        <span className="flex-1 text-[13px] pl-16 pr-8 leading-4 italic text-gray-400">
                            Your session was rescheduled by Coach Name.
                        </span>
                        <span className="absolute right-0 pr-4 flex-none text-gray-500 text-[10px]">09.10</span>
                    </div>  */}

                    <div />
                </div>
            </SimpleBar>

            {/* Footer */}

            {currentChatUser?.is_relation_paused === "yes" &&
                <div className="w-[90%] bg-white absolute bottom-[100px] text-sm text-center text-orange font-ubuntu">
                    Your relation with this client is paused. Hence you can't send messages.
                </div>
            }
            {fileURL &&
                <>
                    {visible && (
                        <div className="absolute bottom-16 left-10">
                            <button type="button" className="close aClass" onClick={removeElement}>
                                <span className="font-bold text-lg text-[#f76411]">&times;</span>
                            </button>
                            <img src={fileURL} alt="" className="w-16 h-16" />
                        </div>
                    )}
                </>

            }
            <div className="flex absolute bottom-6 w-full">
                <div className="flex bg-[#FDE9D8] rounded-[40px] w-[85%] h-10 p-2">
                    <input className="outline-none w-[95%] bg-[#FDE9D8] px-4 text-sm" name="message-text" id="message-text" placeholder="Type a message"
                        onChange={(e) => [
                            setCurrentMessage(e.target.value)
                        ]}
                        disabled={currentChatUser?.is_relation_paused === "yes" ? true : false}
                        value={currentMessage}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                sendMessageHandler()
                            }
                        }}
                        autoFocus
                    />
                    <input ref={sendFileRef}
                        disabled={currentChatUser?.is_relation_paused === "yes" ? true : false}
                        accept="image/*"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                            const files = e.target.files[0];
                            // if (files.size > (70 * 1024)) {
                            // 	toast.error("File size should be less than 70KB");
                            // 	setFile(null)
                            // } else {
                            if (!isOpen) {
                                setIsOpen(true)
                            }

                            var reader = new FileReader();
                            //eslint-disable-next-line
                            var url = reader.readAsDataURL(files);

                            reader.onloadend = function (e) {
                                setFileURL(...[reader.result])
                                //eslint-disable-next-line
                            }.bind(this);
                            // }
                        }} type="file" name="" id="" className="hidden" />
                    <div onClick={() => sendFileRef.current.click()} className="flex justify-center items-center border w-[30px] h-[30px] rounded-full bg-white border-heavyBorder ml-2 mt-[-3px] cursor-pointer">
                        <span className="text-xl font-bold text-orange-500 mt-[-5px]">+</span>
                    </div>
                </div>
                {currentChatUser?.is_relation_paused === "yes" ?
                    <div className="pl-2">
                        <img
                            src={RestrictIcon} alt="" className="border-[#FFFFFF] w-[40px] h-[40px] border-[2px] rounded-3xl cursor-not-allowed" />
                    </div> :
                    <div className="pl-2">
                        <img src={sendButton} onClick={sendMessageHandler} alt="" className="border-[#FFFFFF] w-[40px] h-[40px] border-[2px] rounded-3xl cursor-pointer" id="my-element" data-tooltip-content="Click to Send" />
                    </div>
                }
            </div>
            <ToastContainer />
            <ReactTooltip
                anchorId="my-element"
                variant="info"
                style={{ backgroundColor: "rgb(64 64 63 / 83%)", color: "rgb(255 255 255)" }} className="example-rounded"
                isOpen={isOpen}
            />
        </aside>
    )
}

export default Conversation 