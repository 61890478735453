import React from 'react'
import { useNavigate } from 'react-router-dom'


const Scheduler = ({ event, item, sessionTime, clientId }) => {

	const navigate = useNavigate()

	const navigateToChat = (clientId) => {
		// console.log('ClientId:', clientId);
		navigate(`/coach-chat/${clientId}`)
	}

	return (
		<div className={`w-full border-t-[1px] border-t-gray-400 p-5 relative`}>
			<span className='absolute text-sm text-gray-400 bg-white !p-2 top-[-15px] left-0'>{item}</span>
			{/* {console.log(clientId, 'clientId')} */}
			{event &&
				<div onClick={() => navigateToChat(clientId)} className='cursor-pointer w-10/12 bg-[#FDF5EE] rounded-[40px] flex mx-auto p-3 items-center justify-between gap-8 text-xs'>
					<div className='flex items-center'>
						<div className='w-3 h-3 mr-3 rounded-full bg-[#FFC289]'></div>
						Meeting with {event}
					</div>
					<div className='float-right'>Session Time: {sessionTime === '30_min' ? '30mins' : '1hr'}</div>
				</div>
			}
		</div>
	)
}

export default Scheduler