import Input from '../Login/Input'
import React, { useRef, useState } from 'react'
import calender from '../../assets/images/calender.png'
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import camera from '../../assets/images/camera.png'
import gallery from '../../assets/images/gallery.png'
import { useUpdateProfileMutation } from '../../services/authApi';
import { useNavigate } from 'react-router-dom';

const Right = () => {

    const [arrow, setArrow] = useState("down");
    const [height, setHeight] = useState("h-10");
    const [dateHeight, setDateHeight] = useState("h-10");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [modalDisplay, setModalDisplay] = useState(false);
    const [gender, setGender] = useState("");
    const dateOfBirth = `${day}-${+month + 1}-${year}`;
    // console.log(dateOfBirth);
    const form = useRef(null);
    const [updateProfile, { isLoading }] = useUpdateProfileMutation();
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const [image, setImage] = useState([]);
    //eslint-disable-next-line
    const [imageFile, setimageFile] = useState(null);
    console.log(image);

    const handleClick = () => {
        if (height === "h-10") {
            setHeight("");
            setArrow("up");
        } else {
            setHeight("h-10");
            setArrow("down")
        }
    }

    const handleDateClick = () => {
        if (dateHeight === "h-10") {
            setDateHeight("");
        } else {
            setDateHeight("h-10");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        formData.append('image', ...image);
        try {
            const response = await updateProfile(formData);
            const data = await response.data

            if (data.code === "Success") {
                navigate("/after-login")
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleFileButton = (e) => {
        e.preventDefault();
        hiddenFileInput.current.click();
    };
    // setModalDisplay(false);


    return (
        <div className="w-1/2 xs:w-full sm:w-full md:w-full flex items-center justify-center">
            <div className="w-9/12 xs:w-full p-8  bg-white rounded-[30px]">
                <div onClick={() => setModalDisplay(true)} className="relative overflow-hidden cursor-pointer flex justify-center items-center border-2 w-24 h-24 rounded-full bg-[#FDF5EE] border-[#FFDBB0]">
                    {image.length !== 0 &&
                    <img src={image.length !== 0 ? image[0] : null} alt="" className="fit-image" />
                    }
                    <span className="text-3xl text-gray-500">+</span>
                </div>
                <form ref={form} onSubmit={handleSubmit}>
                    <br />
                    <Input type="text" name="name" placeholder="Name" className="w-full h-10 text-sm px-3 focus:outline-none mt-2 text-gray-500 rounded-[20px] bg-[#FDF5EE]" />

                    <div className={`relative ${height} my-2 overflow-hidden cursor-pointer bg-[#FDF5EE] w-full  rounded-[20px] border-[#FFDBB0] border-2 text-sm text-gray-500`}>
                        <div onClick={handleClick} className="w-full h-10 py-2 px-3">
                            <p className=""> {gender ? gender : "What do you identify as"} </p>
                            {arrow === "down" && <span className="absolute w-2 h-2 top-2 right-4 text-[#F76411]">&#9660;</span>}
                            {arrow === "up" && <span className="absolute w-2 h-2 top-2 right-4 text-[#F76411]">&#9650;</span>}

                        </div>

                        <div className="bg-white p-4">
                            <div className="flex">
                                <div onClick={() => { setGender("Male") }} className={`${gender === "Male" ? "bg-[#F76411] text-white" : "bg-[#FDF5EE]"}  border-[#FFDBB0] w-[100px] border-2 p-2 flex justify-center items-center h-10 rounded-[20px] text-center`}>
                                    Male
                                </div>
                                <div onClick={() => { setGender("Female") }} className={`${gender === "Female" ? "bg-[#F76411] text-white" : "bg-[#FDF5EE]"}  border-[#FFDBB0] w-[100px] border-2 p-2 flex justify-center items-center h-10 rounded-[20px] mx-2 text-center`}>
                                    Female
                                </div>
                            </div>
                            <div className="flex mt-4 border-b-[1px] border-[#FFDBB0] ">
                                <input type="text" name="gender" readOnly value={gender} className="hidden" />
                                <input type="text" placeholder="Specify" onChange={e => setGender(e.target.value)} className="focus:outline-none w-full placeholder:italic bg-transparent border-gray-800 " />
                                <p onClick={() => { setHeight("h-10"); setArrow("down") }} className="font-ubuntu font-bold text-[#F76411]">Confirm</p>
                            </div>

                        </div>

                    </div>

                    <div className={`relative ${dateHeight} overflow-hidden cursor-pointer bg-[#FDF5EE] w-full  rounded-[20px] border-[#FFDBB0] border-2 text-sm text-gray-500`}>
                        <div onClick={handleDateClick} className="w-full h-10 py-2 px-3">
                            <p className=""> {dateOfBirth !== '-1-' ? dateOfBirth : "I was born in 2022, you?"} </p>
                            <span className="absolute w-4 h-4 top-2 right-4 text-[#F76411]"><img src={calender} alt="" /></span>
                        </div>

                        <div className="bg-white p-4">
                            <div className="flex flex-wrap justify-center mb-2 gap-1">
                                <input type="text" name="dob" className="hidden" value={dateOfBirth} readOnly />
                                <YearPicker
                                    defaultValue={"Year"}
                                    start={1900}
                                    end={new Date().getFullYear()}
                                    reverse
                                    required={true}
                                    value={year}
                                    onChange={(e) => setYear(e)}
                                    id={'year'}
                                    classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
                                    optionClasses={'option classes'}
                                />
                                <MonthPicker
                                    defaultValue={"Month"}
                                    endYearGiven    // mandatory if end={} is given in YearPicker
                                    year={year}     // mandatory
                                    required={true} // default is false
                                    value={month}   // mandatory
                                    onChange={(e) => setMonth(e)}
                                    id={'month'}
                                    classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
                                    optionClasses={'option classes'}
                                />
                                <DayPicker
                                    defaultValue={'Day'}
                                    year={year}    // mandatory
                                    month={month}  // mandatory
                                    endYearGiven   // mandatory if end={} is given in YearPicker
                                    required={true}
                                    disabled={!month ? true : false}
                                    value={day}    // mandatory
                                    onChange={(e) => setDay(e)}
                                    id={'day'}
                                    classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
                                    optionClasses={'option classes'}
                                />

                            </div>

                            <div onClick={() => setDateHeight("h-10")} className="font-ubuntu bg-[#FFDBB0] w-[120px] h-10 rounded-[20px] m-auto text-[#F76411] font-bold p-2 flex justify-center items-center text-center">Confirm</div>
                        </div>
                        {modalDisplay &&
                            <div className="fixed bg-[#18181848] w-screen h-screen top-0 left-0 flex justify-center items-center">
                                <div className="bg-white overflow-hidden w-[360px] rounded-[20px]">
                                    <div className="h-10  bg-[#FDF5EE] text-sm text-gray-500 flex justify-between items-center px-3">
                                        <p>Profile Picture</p>
                                        <p onClick={() => setModalDisplay(false)} className="cursor-pointer">&#10005;</p>
                                    </div>

                                    <div className="flex justify-center my-4 w-full">
                                        <div className="relative cursor-pointer flex justify-center items-center border-2 w-24 h-24 rounded-full bg-[#FDF5EE] border-[#FFDBB0]">
                                            <span className="text-3xl text-gray-500">+</span>
                                        </div>
                                    </div>
                                    <p className="m-auto text-center w-full text-sm text-gray-500">Drag photo here</p>
                                    <p className="m-auto text-center w-full text-sm text-gray-500">or</p>
                                    <br />
                                    <div className="flex border-[#FFDBB0] border-2 m-auto rounded-[15px] w-8/12 justify-between p-3">
                                        <div className="w-[100px]">
                                            <div className="bg-[#FDF5EE] w-[60px] p-3 m-auto rounded-[5px]">
                                                <img src={camera} alt="" className="w-6 h-6 m-auto" />
                                            </div>

                                            <p className="m-auto text-center w-full text-sm text-gray-500">Use camera</p>
                                        </div>
                                        <div className="w-[100px]">
                                            <div className="bg-[#FDF5EE] w-[60px] p-3 m-auto rounded-[5px]">
                                                
                                                <input type="file" style={{ display: "none" }} ref={hiddenFileInput}
                                                    onChange={(e) => {
                                                        setimageFile(e.target.files[0])
                                                        const file = e.target.files[0];
                                                        var reader = new FileReader();
                                                        //eslint-disable-next-line
                                                        var url = reader.readAsDataURL(file);

                                                        reader.onloadend = function (e) {
                                                            setImage([reader.result])
                                                            //eslint-disable-next-line
                                                        }.bind(this);
                                                        // console.log(url); 
                                                        setModalDisplay(false);
                                                    }} name="profilepic"></input>
                                                <img src={gallery} alt="" className="w-6 h-6 m-auto" onClick={handleFileButton} />
                                            </div>
                                            <p className="m-auto text-center w-full text-sm text-gray-500">Upload photo</p>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        }
                    </div>
                    <button type='submit' disabled={isLoading} className="font-ubuntu bg-[#FFDBB0] mt-4 w-full h-10 rounded-[20px] m-auto text-[#F76411] font-bold">Continue</button>
                </form>
            </div>
        </div>
    )
}

export default Right