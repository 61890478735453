const thirtymins =

	[{
		time: '00:00',
		event: null
	},
	{
		time: '00:30',
		event: null
	},
	{
		time: '01:00',
		event: null
	},
	{
		time: '01:30',
		event: null
	},
	{
		time: '02:00',
		event: null
	},
	{
		time: '02:30',
		event: null
	},
	{
		time: '03:00',
		event: null
	},
	{
		time: '03:30',
		event: null
	},
	{
		time: '04:00',
		event: null
	},
	{
		time: '04:30',
		event: null
	},
	{
		time: '05:00',
		event: null
	},
	{
		time: '05:30',
		event: null
	},
	{
		time: '06:00',
		event: null
	},
	{
		time: '06:30',
		event: null
	},
	{
		time: '07:00',
		event: null
	},
	{
		time: '07:30',
		event: null
	},
	{
		time: '08:00',
		event: null
	},
	{
		time: '08:30',
		event: null
	},
	{
		time: '09:00',
		event: null
	},
	{
		time: '09:30',
		event: null
	},
	{
		time: '10:00',
		event: null
	},
	{
		time: '10:30',
		event: null
	},
	{
		time: '11:00',
		event: null
	},
	{
		time: '11:30',
		event: null
	},
	{
		time: '12:00',
		event: null
	},
	{
		time: '12:30',
		event: null
	},
	{
		time: '13:00',
		event: null
	},
	{
		time: '13:30',
		event: null
	},
	{
		time: '14:00',
		event: null
	},
	{
		time: '14:30',
		event: null
	},
	{
		time: '15:00',
		event: null
	},
	{
		time: '15:30',
		event: null
	},
	{
		time: '16:00',
		event: null
	},
	{
		time: '16:30',
		event: null
	},
	{
		time: '17:00',
		event: null
	},
	{
		time: '17:30',
		event: null
	},
	{
		time: '18:00',
		event: null
	},
	{
		time: '18:30',
		event: null
	},
	{
		time: '19:00',
		event: null
	},
	{
		time: '19:30',
		event: null
	},
	{
		time: '20:00',
		event: null
	},
	{
		time: '20:30',
		event: null
	},
	{
		time: '21:00',
		event: null
	},
	{
		time: '21:30',
		event: null
	},
	{
		time: '22:00',
		event: null
	},
	{
		time: '22:30',
		event: null
	},
	{
		time: '23:00',
		event: null
	},
	{
		time: '23:30',
		event: null
	}
	];

export default thirtymins