import React, { useEffect, useState } from "react"
import Backdrop from "../../../../UI/Backdrop"
import ModalWrapper from "./ModalWrapper"
import { useUpdateProfileMutation } from '../../../../services/authApi'

const GenderModal = (props) => {
  // const [gender, setGender] = useState(props?.genderField ?? props.currentGender)

  const activeStyles = "bg-transparent text-[#F76411] border-[#F76411]"
  const notActiveStyles = "bg-[#FDE9D8] text-[#727272] border-[#FDE9D8]"

  const [updateProfile, { isLoading, isError, isSuccess }] = useUpdateProfileMutation();

  const handleClick = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', props.nameField);
    formData.append('gender', props.genderField);
    formData.append('dob', props.dobField);

    const response = await updateProfile(formData);
    const data = await response.data;

    try {
      if (data.code === 'Success') {
        props.onCloseProfileModal();
      }
    } catch (error) {
      console.log(error)
      // setError(true)
    }
  }

  return (
    <div>
      <Backdrop />
      <ModalWrapper
        title="What do you Identify as?"
        onCloseProfileModal={props.onCloseProfileModal}
      >
        <form>
          <div className="m-8">
            <div className="flex flex-col justify-start">
              <div className="flex justify-start space-x-4">
                <div className={`py-2 rounded-full px-2 border text-center text-[15px] cursor-pointer ${props.genderField === "Male" ? activeStyles : notActiveStyles}`} onClick={() => { props.setGenderField("Male") }}>Male</div>

                <div className={`py-2 rounded-full px-2 border text-center text-[15px] cursor-pointer ${props.genderField === "Female" ? activeStyles : notActiveStyles}`} onClick={() => { props.setGenderField("Female") }}>Female</div>

                <div className={`py-2 rounded-full px-2 border text-center text-[15px] cursor-pointer ${props.genderField === "Non Binary" ? activeStyles : notActiveStyles}`} onClick={() => { props.setGenderField("Non Binary") }}>Non Binary</div>

                <div className={`py-2 rounded-full px-2 border text-center text-[15px] cursor-pointer ${props.genderField === "Rather Not Mention" ? activeStyles : notActiveStyles}`} onClick={() => { props.setGenderField("Rather Not Mention") }}>Rather Not Mention</div>
              </div>

              {/* <div className="mt-3">
                <input
                  type="text"
                  className="w-full p-1 text-[#F76411] outline-none  border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6 placeholder:italic placeholder:text-[14px]" placeholder="specify"
                  // value={ genderValue == "Male" || "Female" ? "" : genderValue}
                  onChange={e => props.setGenderField(e.target.value)}
                />
              </div> */}
            </div>

            <div className="flex justify-center items-center space-x-5">
              <button
                type="submit"
                className="bg-[#FFDBB0] text-[#F77611] font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
                onClick={(e) => {
                  handleClick(e)
                  // props.setGenderField()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </ModalWrapper>
    </div>
  )
}

export default GenderModal
